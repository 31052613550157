import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';
import { Box, Divider, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import facebook from '../../assets/facebook.svg';
import twiter from '../../assets/twiter.svg';
import likndin from '../../assets/linkdiin.svg';
import gmail from '../../assets/gmail.svg';

export default function ShareDialog() {
	const [open, setOpen] = React.useState(false);
	const [copied, setCopied] = React.useState(false);

	const [url, setUrl] = React.useState('');

	React.useEffect(() => {
		const fetchUrl = async () => {
			setUrl(window.location.href);
		};
		fetchUrl();
	}, []);

	// const handleClickOpen = () => {
	// 	setOpen(true);
	// };
	// const handleClose = () => {
	// 	setOpen(false);
	// };

	// const handleCopy = () => {
	// 	navigator.clipboard
	// 		.writeText(url)
	// 		.then(() => {
	// 			setCopied(true);
	// 			setTimeout(() => setCopied(false), 500);
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error copying text to clipboard:', error);
	// 		});
	// };

	const handleClickOpen = async () => {
		await handleCopy();
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCopy = () => {
		return new Promise((resolve, reject) => {
			navigator.clipboard
				.writeText(url)
				.then(() => {
					setCopied(true);
					setTimeout(() => {
						setCopied(false);
						resolve();
					}, 500);
				})
				.catch((error) => {
					console.error('Error copying text to clipboard:', error);
					reject(error);
				});
		});
	};

	return (
		<>
			<Button
				variant='contained'
				sx={{
					fontFamily: 'Figtree',
					fontWeight: '500px',
					fontSize: '14px',
					border: '1px solid #D8D8D9',
					color: '#26282C',
					borderRadius: '100px',
					textTransform: 'none',
				}}
				startIcon={<ShareIcon sx={{ color: '#FF5E29' }} />}
				style={{
					background: '#FFFFFF',
				}}
				onClick={handleClickOpen}
			>
				Share
			</Button>
			{/* <IconButton onClick={handleClickOpen}>
				<ShareIcon sx={{ color: '#FF5E29' }} />
			</IconButton> */}
			<Dialog
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={open}
				fullWidth
				maxWidth='sm'
				sx={{
					p: '18px',
					'& .MuiDialogContent-root': { height: '240px' },
				}}
			>
				<DialogTitle sx={{}} id='customized-dialog-title'></DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
						m: 0,
						p: 2,
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<Typography
						sx={{
							fontFamily: 'Figtree',

							fontSize: '24px',
							lineHeight: '24px',
							textTransform: 'none',
							color: '#26282C',
							fontWeight: 'bold',
							mt: '4px',
						}}
					>
						Share your Report
					</Typography>
					<Typography
						sx={{
							fontFamily: 'Figtree',
							fontWeight: '400px',
							fontSize: '14px',
							lineHeight: '21px',
							textTransform: 'none',
							color: '#67696B',
							mt: '8px',
						}}
					>
						Give people access to your report and start collaborating.
					</Typography>
					<Typography
						sx={{
							fontFamily: 'Figtree',

							fontSize: '14px',
							lineHeight: '14px',
							textTransform: 'none',
							color: '#67696B',
							mt: '16px',
							fontWeight: 'bold',
						}}
					>
						Direct Link
					</Typography>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							border: '1px solid #D8D8D9',
							borderRadius: '5px',
							p: '12px',
							mt: '6px',
							// width: '392px',
						}}
					>
						<Typography
							sx={{
								fontFamily: 'Figtree',

								fontSize: '14px',
								lineHeight: '16px',
								textTransform: 'none',
								color: '#202020',

								fontWeight: 'bold',
							}}
						>
							{url}
						</Typography>
						<Tooltip title={copied ? 'Copied to clipboard' : 'Copy URL'} arrow>
							<IconButton onClick={handleCopy}>
								<ContentCopyIcon
									sx={{ width: '24px', height: '24px', color: '#FF5E29' }}
								/>
							</IconButton>
						</Tooltip>
					</Box>
					{/* <Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							mt: '16px',
							alignItems: 'center',
						}}
					>
						<Divider sx={{ width: '250px', color: '#D8D8D9' }} />
						<Typography
							sx={{
								fontFamily: 'Figtree',

								fontSize: '12px',
								lineHeight: '18px',
								textTransform: 'none',
								color: '#202020',
								fontWeight: 'bold',
							}}
						>
							OR
						</Typography>
						<Divider sx={{ width: '250px', color: '#D8D8D9' }} />
					</Box>

					<Typography
						sx={{
							fontFamily: 'Figtree',

							fontSize: '14px',
							lineHeight: '14px',
							textTransform: 'none',
							color: '#202020',
							mt: '16px',
							fontWeight: 'bold',
						}}
					>
						Share report via
					</Typography>

					<Box sx={{ display: 'flex', mt: '12px' }}>
						<a
							href='https://www.facebook.com'
							target='_blank'
							// onClick={handleCopy}
						>
							<img src={facebook} alt='Facebook' />
						</a>
						<a
							href='https://twitter.com'
							target='_blank'
							// onClick={handleCopy}
							style={{ marginLeft: '8px' }}
						>
							<img src={twiter} alt='Twitter' />
						</a>
						<a
							href='https://gmail.com'
							target='_blank'
							// onClick={handleCopy}
							style={{ marginLeft: '8px' }}
						>
							<img src={gmail} alt='Gmail' />
						</a>
						<a
							href='https://www.linkedin.com'
							target='_blank'
							// onClick={handleCopy}
							style={{ marginLeft: '8px' }}
						>
							<img src={likndin} alt='LinkedIn' />
						</a>
					</Box> */}
				</DialogContent>
			</Dialog>
		</>
	);
}
