import { createContext, useContext, useReducer } from 'react';
import { questionReducer } from './QuestionReducer';

const QuestionContext = createContext();

export const useQuestionContext = () => useContext(QuestionContext);

const initialState = {
	selectedValues: [],
	data: [],
	progress: 0,
	currentCategory: null,
	reportData: '',
	forwardCurrentIndex: 0,
	websiteName: '',
	formDataResponse: '',
	sliderValuesSet: '',
	sliderValuesRange: '',
	locationValuesSet: '',
	showConfirmationBox: 'true',
};

// question.forEach((q) => {
// 	const obj = {}; // Create a new object for each question
// 	obj[q.name] = Array(); // Initialize array for question with null values
// 	initialState.selectedValues.push(obj); // Push the object into selectedValues array
// });

export const QuestionProvider = ({ children }) => {
	const [state, dispatch] = useReducer(questionReducer, initialState);

	return (
		<QuestionContext.Provider value={{ state, dispatch }}>
			{children}
		</QuestionContext.Provider>
	);
};
