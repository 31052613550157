import { Box, Typography } from '@mui/material';
import React from 'react';
import logo from '../../assets/logo.svg';
import logowhite from '../../assets/whitelogo.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Footer = ({ landingpage, emailpage, reportData }) => {
	console.log('🚀 ~ Footer ~ reportData:', reportData);
	const handleConsultingClick = () => {
		const profileData = reportData?.user_data || {
			fname: '',
			lname: '',
			website: '',
			email: '',
			phone: '',
			industry: '',
			monthly_budget: '',
		};

		// Extract the required values with defaults
		const { fname, lname, website, email, phone, industry, monthly_budget } =
			profileData;

		const consultingUrl =
			'https://advanced.npdigital.com/maximize-your-email-roi-with-np-digital/?' +
			'utm_medium=ref&utm_source=mail-grader&utm_campaign=us-mkt-campaigns-sql-tool-mail-grader&utm_content=mail-grader-consulting-page&utm_term=in-tool-consulting-cta' +
			`&cf-phone=${encodeURIComponent(phone || '')}` +
			`&cf-email=${encodeURIComponent(email || '')}` +
			`&cf-fname=${encodeURIComponent(fname || '')}` +
			`&cf-lname=${encodeURIComponent(lname || '')}` +
			`&cf-url=${encodeURIComponent(website || '')}` +
			`&cf-industry=${encodeURIComponent(industry || '')}` +
			`&cf-budget=${encodeURIComponent(monthly_budget || '')}`;

		window.open(consultingUrl, '_blank');
	};

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',

				alignItems: 'center',

				position: landingpage || emailpage ? '' : 'fixed',
				bgcolor: landingpage ? '#3C3B41' : 'white',
				left: '0',
				bottom: '8px',
				width: '100%',
				p: '8px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					width: '258.94px',
					height: '22px',
					justifyContent: 'space-between',
					alignItems: 'center',

					ml: '40px',
				}}
			>
				<Typography
					sx={{
						fontSize: '14px',
						fontFamily: 'Figtree',
						fontWeight: '400px',
						color: landingpage ? '#FFFFFF' : '#BEBFC0',
					}}
				>
					Powered by
				</Typography>

				<Box
					sx={{
						width: '4px',
						height: '4px',
						bgcolor: '#FFCFBF',
						borderRadius: '1000px',
					}}
				/>

				<Typography
					sx={{
						fontWeight: '600px',
						fontSize: '16px',
						fontFamily: 'Figtree',

						color: landingpage ? '#FFFFFF' : '#26282C',
					}}
				>
					Mail Grader
				</Typography>

				<Box
					sx={{
						height: '15px',
						border: '1px solid #E9E9EA',
					}}
				/>
				<img
					src={landingpage ? logowhite : logo}
					style={{
						width: '56px',
						height: '22px',
						cursor: 'pointer',
					}}
					alt='question-image'
					onClick={handleConsultingClick}
				/>
			</Box>
			{!landingpage && (
				<Box
					component='a' // Use Box as an anchor element
					href='https://neilpatel.com/contact/' // URL to open
					target='_blank' // Open link in a new tab
					rel='noopener noreferrer' // Recommended for security reasons when using target="_blank"
					sx={{
						height: '44px',
						width: '86px',
						borderRadius: '1000px',
						bgcolor: '#F5F5F5',
						display: 'flex',
						cursor: 'pointer',
						alignItems: 'center',
						justifyContent: 'center',
						mr: '40px',
						textDecoration: 'none', // Remove underline
					}}
				>
					<HelpOutlineIcon
						sx={{ fontSize: '20px', color: '#A3B0B3', mr: '4px' }}
					/>
					<Typography
						sx={{
							fontWeight: '500px',
							fontSize: '12px',
							color: '#26282C',
							fontFamily: 'Figtree',
						}}
					>
						Help
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default Footer;
