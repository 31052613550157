import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { QuestionProvider } from './context/QuestionContext';
import MainPage from './component/questionnaire /MainPage';
import Report from './component/report/Report';
import EmailForm from './component/report/EmailForm';
import LandingPage from './component/LandingPage';

const Routing = () => {
	return (
		<Router>
			<QuestionProvider>
				<Routes>
					<Route path='/' element={<LandingPage />} />
					<Route path='/question' element={<MainPage />} />
					<Route path='/emailform' element={<EmailForm />} />
					<Route path='/report/:reportId' element={<Report />} />
				</Routes>
			</QuestionProvider>
		</Router>
	);
};

export default Routing;
