import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import logo from '../../assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import { useQuestionContext } from '../../context/QuestionContext';

export default function AlertDialog({ state, setShowDialog }) {
	const [open, setOpen] = React.useState();
	const { dispatch } = useQuestionContext();

	const navigate = useNavigate();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		// if (state) {
		// 	setShowDialog(false);
		// }
	};

	const handleNavigate = () => {
		navigate('/');
		dispatch({ type: 'RESET_SELECTED_VALUES' });
		dispatch({ type: 'RESET_STORE_SLIDER_VALUE' });
		dispatch({ type: 'RESET_STORE_RANGE_VALUE' });
	};

	return (
		<React.Fragment>
			{/* {state ? (
				''
			) : ( */}
			<img
				src={logo}
				style={{
					width: '70px',
					height: '28px',
					cursor: 'pointer',
				}}
				alt='question-image'
				onClick={handleClickOpen}
			/>
			{/* )} */}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>{'Exit Confirmation'}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Are you sure you want to leave? You will lose your progress from the
						tool if you proceed.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Disagree</Button>
					<Button onClick={handleNavigate}>Agree</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
