import {
	Box,
	Button,
	Container,
	Divider,
	LinearProgress,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../questionnaire /Footer';
import frame from '../../assets/frame.svg';
import reportmeter from '../../assets/meter.svg';
import flag from '../../assets/flag.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import vector from '../../assets/vector.svg';
import ReactSpeedometer from 'react-d3-speedometer';
import { useParams } from 'react-router-dom';
import { useQuestionContext } from '../../context/QuestionContext';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShareDialog from './ShareDialog';

import NpImage from '../../assets/NP Image.png';
import Header from '../questionnaire /Header';

let questionkey = '';

const reportDat = [
	{
		id: 1,
		user: '8bf23440-7979-4f5c-920e-d35f4fd82afe',
		report_id: '35b20603-5633-4c9d-937e-2771df783b82',
		data: {
			total_score_percentage: 37.4,
			categories_score: [
				{
					id: 1,
					name: 'Sends',
					total_questions: 8,
					correct_answers: 2,
				},
				{
					id: 2,
					name: 'Analytics',
					total_questions: 3,
					correct_answers: 0,
				},
				{
					id: 3,
					name: 'Creative',
					total_questions: 3,
					correct_answers: 1,
				},
				{
					id: 4,
					name: 'Contacts',
					total_questions: 7,
					correct_answers: 6,
				},
				{
					id: 5,
					name: 'Automation',
					total_questions: 6,
					correct_answers: 1,
				},
			],
			recommended_actions: [
				{
					category: 'Sends',
					questions: [
						{
							question: 'Are you sending any abandonment workflows?',
							recommended_actions: {
								No: 'Set up an Abandonment flow (could be browse, product, or cart based) to send leads into flows personalized to their interest and browsing behavior.',
							},
						},
					],
				},
				{
					category: 'Analytics',
					questions: [
						{
							question:
								'Do you analyze your email performance at least once per month?',
							recommended_actions: {
								No: "Without a lead capture pop up form, you aren't harnessing all the traffic coming to your site to grow your top of funnel. Create a form via your ESP-website connection so that all leads enter directly into your mailing list.",
							},
						},
						{
							question: 'Do you have issues with click rate?',
							recommended_actions: {
								Zero: 'Call to actions are the only way to track email performance and guide leads to the next step in their journey. Always utilize one actionable CTA per email.',
							},
						},
					],
				},
				{
					category: 'Creative',
					questions: [
						{
							question: 'How many call to actions are you using per email?',
							recommended_actions: {
								'More than one':
									"Using more than 1 call to action can split readers' attention. If you have one goal in mind for the email, only have a CTA for that. If you must have a secondary CTA, make sure it is smaller and farther down in the email.",
							},
						},
						{
							question: 'Are your emails responsive for desktop and mobile?',
							recommended_actions: {
								No: 'Leverage tools within your ESP or HTML emails to make your emails responsive.',
							},
						},
					],
				},
				{
					category: 'Contacts',
					questions: [
						{
							question: 'Do you regularly clean your list?',
							recommended_actions: {
								Yes: "Issues with click rate mean people are interested in your emails, but then the info provided isn't what they expected. Make sure you are sending relevant emails to segmented lists and prioritizing your CTA.",
							},
						},
					],
				},
				{
					category: 'Automation',
					questions: [
						{
							question:
								'Are your email service provider and eCommerce store connected?',
							recommended_actions: {
								No: "Connect your ESP and eCommerce store. Some platforms have a native integration, and this can be checked on your ESP's website. If not, a 3rd party application such as Zapier will be needed. \r\n\r\nWithout your ESP and eComm store connected, you won't be able to track revenue and leverage shopping behavior for emails.",
							},
						},
						{
							question: 'Are your email service provider and CRM connected?',
							recommended_actions: {
								No: "Connect your ESP and CRM. Some platforms have a native integration, and this can be checked on your ESP's website. If not, a 3rd party application such as Zapier will be needed. \r\n\r\nWithout your ESP and CRM connected, you won't be able to track lead progression and leverage stage in the buyer's journey for emails.",
							},
						},
						{
							question:
								'Are your email service provider and website connected?',
							recommended_actions: {
								No: "Connect your ESP and website (CMS). Some platforms have a native integration, and this can be checked on your ESP's website. If not, a 3rd party application such as Zapier will be needed. \r\n\r\nWithout your ESP and website connected, you won't be able to do behavior-based workflows.",
							},
						},
						{
							question: 'Are your DNS records verified?',
							recommended_actions: {
								No: "Start the verification process in the ESP. You will receive a variation of CNAME or TXT record values that need to be added to your DNS hosting.\r\n\r\nThis is crucial for deliverabiity so that emails come from your domain as opposed to the ESP's.",
							},
						},
					],
				},
			],
		},
	},
];

const getCategoryStatus = (correctAnswers, totalQuestions) => {
	const percentage = (correctAnswers / totalQuestions) * 100;

	if (percentage >= 60) {
		return {
			icon: <CheckCircleIcon sx={{ color: '#60E2AC' }} />,
			color: '#60E2AC',
		};
	} else if (percentage >= 30) {
		return { icon: <ErrorIcon sx={{ color: '#F8BC54' }} />, color: '#F8BC54' };
	} else if (percentage < 30) {
		return {
			icon: <CheckCircleIcon sx={{ color: '#E53435' }} />,
			color: '#E53435',
		};
	}
};

const Report = () => {
	const [selectedCategory, setSelectedCategory] = useState('Sends');
	const { reportId } = useParams();
	const { state, dispatch } = useQuestionContext();
	const { reportData, formDataResponse } = state;
	console.log('🚀 ~ Report ~ reportData:', reportData);
	const website = reportData?.user_data?.website;

	const score = reportData?.data?.total_score_percentage;

	let title, content;
	if (score <= 33.333) {
		title = 'Here’s your email score. Your strategy could use some TLC.';
		content =
			'Not the score you were expecting? Small things can have a big impact on your email marketing results, so this doesn’t mean you have to rethink your entire approach. Our team can help you identify the issues that affect your performance and move that needle from red to green!';
	} else if (score <= 67) {
		title =
			'Woohoo, here’s your email score... and it shows you’re on the right track!';
		content =
			'Your score reflects your understanding of key email marketing concepts and highlights your dedication to impactful messaging. With a few tweaks, you could transform your strategy into an ROI powerhouse! We can work together to identify those adjustments and unlock your full potential.';
	} else {
		title = "Wohooo! Here's your email score. You're doing a great job!";
		content =
			'Your email score reflects outstanding performance, showcasing effective strategies, quality content, and engaged audiences. It highlights your dedication to impactful messaging and strong subscriber connections.';
	}

	const handleConsultingClick = () => {
		// Access the profile_data from the response
		const profileData = reportData?.user_data || {
			fname: '',
			lname: '',
			website: '',
			email: '',
			phone: '',
			industry: '',
			monthly_budget: '',
		};

		// Extract the required values with defaults
		const { fname, lname, website, email, phone, industry, monthly_budget } =
			profileData;

		// Construct the consulting URL with extracted values
		const consultingUrl =
			'https://advanced.npdigital.com/maximize-your-email-roi-with-np-digital/?' +
			'utm_medium=ref&utm_source=mail-grader&utm_campaign=us-mkt-campaigns-sql-tool-mail-grader&utm_content=mail-grader-consulting-page&utm_term=in-tool-consulting-cta' +
			`&cf-phone=${encodeURIComponent(phone || '')}` + // No need to check for existence, already initialized
			`&cf-email=${encodeURIComponent(email || '')}` + // No need to check for existence, already initialized
			`&cf-fname=${encodeURIComponent(fname || '')}` + // No need to check for existence, already initialized
			`&cf-lname=${encodeURIComponent(lname || '')}` + // No need to check for existence, already initialized
			`&cf-url=${encodeURIComponent(website || '')}` + // No need to check for existence, already initialized
			`&cf-industry=${encodeURIComponent(industry || '')}` + // No need to check for existence, already initialized
			`&cf-budget=${encodeURIComponent(monthly_budget || '')}`; // No need to check for existence, already initialized

		// Open the consulting URL in a new tab
		window.open(consultingUrl, '_blank');
	};
	const handleCategoryClick = (category) => {
		setSelectedCategory(category);
	};

	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/survey/report/?report_uuid=${reportId}`
		)
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				dispatch({ type: 'FETCH_REPORT_DATA_SUCCESS', payload: data });
			})
			.catch((error) => {
				console.error('Error fetching data:', error);
			});
	}, [reportId]);
	const emailpage = true;
	const reportpage = true;

	return (
		<Box
			sx={{
				height: '100vh',

				display: 'flex',
				flexDirection: 'column',

				justifyContent: 'space-between',
			}}
		>
			<Header handleConsultingClick={handleConsultingClick} />

			<Container>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',

						mt: '10px',
						mb: '25px',
					}}
				>
					<Typography
						sx={{
							fontFamily: 'Figtree',
							fontWeight: '500px',
							fontSize: '18px',
							lineHeight: '23.4px',
						}}
					>
						The Mail Grader report for
						<a
							href={`https://${website}`}
							//href={`https://website}`}
							target='_blank'
							style={{
								color: '#FF5E29',
								textDecoration: 'none',
								marginLeft: '3px',
								marginRight: '3px',
							}}
						>
							{website}
						</a>
						is ready!
						{/* {`The Mail Grader report for ${website} is ready!`} */}
					</Typography>
					<Box>
						<ShareDialog />

						{/* <Button
							variant='contained'
							sx={{
								fontFamily: 'Figtree',
								fontWeight: '500px',
								fontSize: '14px',
								border: '1px solid #D8D8D9',
								color: '#26282C',
								borderRadius: '100px',
								textTransform: 'none',
								ml: '10px',
							}}
							startIcon={<FileDownloadIcon sx={{ color: '#FF5E29' }} />}
							style={{
								background: '#FFFFFF',
							}}
							
						>
							Download
						</Button> */}
					</Box>
				</Box>
				<Box
					sx={{
						mb: '25px',

						height: '160px',
					}}
				>
					<Box
						sx={{
							bgcolor: 'rgba(255, 223, 212, 1)',

							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',

							border: '1px solid #FF5E29',
							borderRadius: '5px',
							px: '15px',
							pt: '15px',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<img
								src={NpImage}
								alt='npimage'
								onClick={handleConsultingClick}
								style={{ cursor: 'pointer' }}
							/>
							<Box>
								<Typography
									sx={{
										fontFamily: 'Figtree',
										fontSize: '18px',
										lineHeight: '21.6px',
										fontWeight: 'bold',
									}}
								>
									<span
										style={{
											color: 'rgba(255, 94, 41, 1)',
											marginRight: '4px',
										}}
									>
										Low Email Engagement
									</span>
									is Costing you Customers!
								</Typography>
								<Typography
									sx={{
										fontFamily: 'Figtree',
										fontSize: '12px',
										lineHeight: '18px',
										fontWeight: '400px',
										color: '#67696B',
										mt: '4px',
									}}
								>
									Avoid lost leads and missed sales by optimizing your email
									strategy today. My team can help you multiply your email ROI
									through:
								</Typography>

								<Box
									sx={{
										width: '25px',
										border: '1px solid #DCB7AA',
										mt: '8px',
										mb: '8px',
									}}
								/>

								<Box
									sx={{
										width: '334px',
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<Box
										sx={{
											display: 'flex',

											flexDirection: 'column',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Box
												sx={{
													width: '4px',
													height: '4px',
													bgcolor: '#FF5E29',
													borderRadius: '1000px',
												}}
											/>
											<Typography
												sx={{
													fontFamily: 'Figtree',
													fontSize: '12px',
													lineHeight: '20.4px',
													fontWeight: '400px',
													color: '#26282C',
													ml: '2px',
													cursor: 'pointer',
												}}
												onClick={handleConsultingClick}
											>
												Audience Segmentation
											</Typography>
										</Box>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Box
												sx={{
													width: '4px',
													height: '4px',
													bgcolor: '#FF5E29',
													borderRadius: '1000px',
												}}
											/>
											<Typography
												sx={{
													fontFamily: 'Figtree',
													fontSize: '12px',
													lineHeight: '20.4px',
													fontWeight: '400px',
													color: '#26282C',
													ml: '2px',
													cursor: 'pointer',
												}}
												onClick={handleConsultingClick}
											>
												Campaign automation
											</Typography>
										</Box>
									</Box>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Box
												sx={{
													width: '4px',
													height: '4px',
													bgcolor: '#FF5E29',
													borderRadius: '1000px',
												}}
											/>
											<Typography
												sx={{
													fontFamily: 'Figtree',
													fontSize: '12px',
													lineHeight: '20.4px',
													fontWeight: '400px',
													color: '#26282C',
													ml: '2px',
													cursor: 'pointer',
												}}
												onClick={handleConsultingClick}
											>
												Data-driven personalization
											</Typography>
										</Box>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Box
												sx={{
													width: '4px',
													height: '4px',
													bgcolor: '#FF5E29',
													borderRadius: '1000px',
												}}
											/>
											<Typography
												sx={{
													fontFamily: 'Figtree',
													fontSize: '12px',
													lineHeight: '20.4px',
													fontWeight: '400px',
													color: '#26282C',
													ml: '2px',
													cursor: 'pointer',
												}}
												onClick={handleConsultingClick}
											>
												Creative optimization
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box
							sx={{
								backgroundImage: 'linear-gradient(90deg, #FFDFD4, #FFFFFF)',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',

								justifyContent: 'center',
								p: '14px',
								borderRadius: '5px',
							}}
						>
							<Box
								sx={{
									width: '238px',
								}}
							>
								<Typography
									sx={{
										fontFamily: 'Figtree',
										fontSize: '14px',
										lineHeight: '21px',
										fontWeight: 'bold',
										textAlign: 'center',
										cursor: 'pointer',
									}}
									onClick={handleConsultingClick}
								>
									<span
										style={{
											color: 'rgba(255, 94, 41, 1)',
											marginRight: '4px',
										}}
									>
										Book a consultation today
									</span>
									and watch your email performance soar!
								</Typography>
							</Box>

							<Button
								variant='contained'
								onClick={handleConsultingClick}
								size='large'
								sx={{
									bgcolor: '#4285F4',

									mt: '8px',

									fontFamily: 'Figtree',
									fontSize: '11px',
									lineHeight: '12px',
									borderRadius: '15px',
								}}
							>
								Schedule Your Email Strategy Session
							</Button>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Box
							sx={{
								border: '1.5px solid ',
								borderImage: 'linear-gradient(45deg, #FF5E29, #FF9D29)',
								borderImageSlice: 1,
								borderRadius: '5px',
								display: 'flex',
								alignItems: 'center',
								padding: '14px',
								cursor: 'pointer',

								maxWidth: '600px',
							}}
							onClick={handleConsultingClick}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									width: '280px',
									mb: '25px',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<img src={frame} alt='frame' />
									<Typography
										sx={{
											fontSize: '20px',
											fontWeight: '600',
											fontFamily: 'Figtree',
											color: '#26282C',
											ml: '16px',
										}}
									>
										{title}
									</Typography>
								</Box>
								<Typography
									sx={{
										fontSize: '14px',
										fontWeight: '400',
										fontFamily: 'Figtree',
										color: '#67696B',
										lineHeight: '22.4px',
										mt: '16px',
									}}
								>
									{content}
								</Typography>
							</Box>

							<Box position='relative' width='200px' height='200px'>
								<ReactSpeedometer
									value={reportData?.data?.total_score_percentage}
									needleHeightRatio={0.7}
									maxValue={100}
									needleColor='#3C3B41'
									ringWidth={10}
									segments={3}
									segmentColors={['#E53435', '#F8BC54', '#60E2AC']}
									valueTextFontSize='32px'
									valueTextFontWeight='bold'
									paddingVertical={10}
								/>
							</Box>
						</Box>
						<Box
							sx={{
								border: '1px solid #EBEBEC ',
								display: 'flex',
								flexDirection: 'column',
								borderRadius: '5px',
								mt: '24px',

								alignItems: 'center',
								py: '22px',

								maxWidth: '600px',
							}}
						>
							<Box
								sx={{
									width: '600px',

									display: 'flex',
									flexDirection: 'column',
								}}
							>
								{reportData?.data?.categories_score?.map((category, index) => (
									<Box key={index}>
										<Box
											sx={{
												display: 'flex',

												justifyContent: 'space-between',
												px: '8px',

												mb: '14px',
												mt: '14px',
											}}
										>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												{
													getCategoryStatus(
														category.correct_answers,
														category.total_questions
													).icon
												}
												<Typography
													sx={{
														fontFamily: 'Figtree',
														fontWeight: '400px',
														fontSize: '16px',
														lineHeight: '18px',
														color: '#26282C',
														ml: '12px',
													}}
												>
													{category.name}
												</Typography>
											</Box>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<LinearProgress
													variant='determinate'
													value={
														(category.correct_answers /
															category.total_questions) *
														100
													}
													sx={{
														width: '132px',
														height: '8px',
														borderRadius: '5px',
														backgroundColor: '#F5F5F5',
														'& .MuiLinearProgress-bar': {
															backgroundColor: getCategoryStatus(
																category.correct_answers,
																category.total_questions
															).color,
														},
													}}
												/>
												<Typography
													sx={{
														fontSize: '18px',
														fontWeight: '600px',
														fontFamily: 'Figtree',
														lineHeight: '18px',
														color: '#26282C',
														fontWeight: 'bold',
														ml: '12px',
													}}
												>
													{`${category.correct_answers}/${category.total_questions}`}
												</Typography>
											</Box>
										</Box>
										<Divider
											sx={{
												visibility:
													index ===
													reportData?.data?.categories_score?.length - 1
														? 'hidden'
														: 'visible',
											}}
										/>
									</Box>
								))}
							</Box>
						</Box>
					</Box>

					<Box
						sx={{
							border: '1px solid #EBEBEC ',
							display: 'flex',
							borderRadius: '5px',

							maxWidth: '525px',
						}}
					>
						<Box
							sx={{
								m: '16px',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '254px',
								}}
							>
								<img src={flag} alt='flag' />
								<Typography
									sx={{
										fontFamily: 'Figtree',
										fontSize: '20px',
										fontWeight: '600',
										lineHeight: '20px',
										color: '#26282C',
									}}
								>
									Recommended Actions
								</Typography>
							</Box>
							<Box
								sx={{
									bgcolor: '#F7F8F9',
									borderRadius: '88px',
									mt: '24px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									px: '8px',
									py: '2px',
								}}
							>
								{reportData?.data?.recommended_actions?.map((action) => (
									<Box
										key={action.category}
										onClick={() => handleCategoryClick(action.category)}
										sx={{
											// width: '80px',
											px: '8px',
											height: '30px',
											borderRadius: '25px',
											bgcolor:
												selectedCategory === action.category
													? '#FFFFFF'
													: '#EBEEF0',
											boxShadow: '#2020200F',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											cursor: 'pointer',
										}}
									>
										<Typography
											sx={{
												fontFamily: 'Figtree',
												fontSize: '12px',
												lineHeight: '14px',
												fontWeight: '500',
												color:
													selectedCategory === action.category
														? '#26282C'
														: '#67696B',
											}}
										>
											{action.category}
										</Typography>
									</Box>
								))}
							</Box>
							{selectedCategory && (
								<Box
									sx={{
										height: '550px',
										mt: '24px',

										overflowY: 'auto',
										'&::-webkit-scrollbar': {
											width: '4px',
										},
										'&::-webkit-scrollbar-track': {
											background: '#D1D7D9',
										},
										'&::-webkit-scrollbar-thumb': {
											background: '#888',
											borderRadius: '5px',
										},
										'&::-webkit-scrollbar-thumb:hover': {
											background: '#555',
										},
									}}
								>
									{reportData?.data?.recommended_actions?.map(
										(action) =>
											action.category === selectedCategory && (
												<Box key={action.category}>
													{action.questions.map((question, index) => (
														<Box key={index}>
															<Box
																sx={{
																	display: 'flex',

																	alignItems: 'center',
																}}
															>
																<Box
																	sx={{
																		width: '28px',
																		height: '28px',
																		border: '3px solid  #EBEEF0',
																		borderRadius: '1000px',
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		bgcolor: '#FFFFFF',
																	}}
																>
																	<Typography
																		sx={{
																			fontFamily: 'Figtree',
																			fontSize: '14px',
																			lineHeight: '22.4px',
																			fontWeight: '600',
																			color: '#26282C',
																		}}
																	>
																		{index + 1}
																	</Typography>
																</Box>
																<Typography
																	sx={{
																		fontFamily: 'Figtree',
																		fontSize: '14px',
																		lineHeight: '22.4px',
																		fontWeight: '600',
																		color: '#26282C',

																		ml: '12px',
																	}}
																>
																	{question.question}
																</Typography>
															</Box>
															<Box sx={{ ml: '45px', display: 'flex' }}>
																<img
																	src={vector}
																	alt='vector'
																	style={{ width: '13px', height: '13px' }}
																/>
																<Typography
																	sx={{
																		fontFamily: 'Figtree',
																		fontSize: '14px',
																		lineHeight: '14px',
																		fontWeight: '500',
																		color: '#FF5E29',

																		fontStyle: 'italic',
																		ml: '4px',
																		pt: '4px',
																	}}
																>
																	{Object.keys(question.recommended_actions)}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: 'flex',
																	ml: '60px',
																	mt: '8px',
																}}
															>
																<img
																	src={flag}
																	alt='flag'
																	style={{
																		width: '16px',
																		height: '16px',
																		marginTop: '4px',
																	}}
																/>
																<Typography
																	sx={{
																		fontFamily: 'Figtree',
																		fontSize: '14px',
																		lineHeight: '21px',
																		fontWeight: '400',
																		color: '#67696B',
																		// maxWidth: '410px',
																		ml: '4px',
																	}}
																>
																	{
																		question.recommended_actions[
																			Object.keys(question.recommended_actions)
																		]
																	}
																</Typography>
															</Box>
														</Box>
													))}
												</Box>
											)
									)}
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</Container>
			<Footer
				emailpage={emailpage}
				handleConsultingClick={handleConsultingClick}
				reportData={reportData}
			/>
		</Box>
	);
};

export default Report;
