import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	Typography,
	Button,
	Box,
	Radio,
	Slider,
	Stack,
	Skeleton,
	Slide,
} from '@mui/material';
import questionicon from '../../assets/question.svg';
import handupgrayicon from '../../assets/handsupgrey.svg';
import handdowngrayicon from '../../assets/handsdown-grey.svg';
import handdownorangeicon from '../../assets/handsdown-color.svg';
import handuporangeicon from '../../assets/handsup-color.svg';
import textonly from '../../assets/text-only -orange.svg';
import textonlywhite from '../../assets/text-only.svg';
import designed from '../../assets/designed .svg';
import designedOrange from '../../assets/designed orange.svg';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import styled from '@emotion/styled';
import { useQuestionContext } from '../../context/QuestionContext';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import QuestionBar from './QuestionBar';
import AlertDialog from '../report/AlertDilog';

const CustomRadio = styled(Radio)(({ theme }) => ({
	color: '#D8D8D9',
	'&.Mui-checked': {
		color: '#FF5E29',
	},
}));

const PrettoSlider = styled(Slider)({
	color: '#FF5E29',
	height: 12,
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-thumb': {
		height: 28,
		width: 28,
		backgroundColor: '#FF5E29',
		border: '6px solid rgb(255, 255, 255)',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&::before': {
			display: 'none',
		},
	},
	'& .MuiSlider-valueLabel': {
		lineHeight: 1.2,
		fontSize: 16,
		fontWeight: 600,
		background: 'unset',
		padding: 0,
		marginTop: '-14px',
		width: '89px',
		height: '45px',
		borderRadius: '6px',
		backgroundColor: '#26282C',
	},
	'& .MuiSlider-rail': {
		opacity: 1,

		backgroundColor: '#F1F3F4',
	},
});

const Question = ({ questions, UUID, allQuestionsAndAnswers }) => {
	const { state, dispatch } = useQuestionContext();
	const {
		selectedValues,
		data,
		progress,
		forwardCurrentIndex,
		sliderValuesSet,
		sliderValuesRange,
		showConfirmationBox,
	} = state;

	// const [showDialog, setShowDialog] = useState(false);

	// console.log('🚀 ~ Question ~ showDialog:', showDialog);

	const [scrollAnimation, setScrollAnimation] = useState(true);

	const [scrollDirection, setScrollDirection] = useState('up');
	const [backButtonClicked, setBackButtonClicked] = useState(false);

	const [firstQuestion, setFirstQuestion] = useState(false);
	const [rangeButton, setRangeButton] = useState(false);
	const [nextButton, setNextButton] = useState(true);
	const [animationActive, setAnimationActive] = useState(false);
	const [confirmation, setConfirmation] = useState(false);

	console.log('🚀 ~ Question ~ rangeButton:', rangeButton);

	const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
	//console.log('🚀 ~ Question ~ currentCategoryIndex:', currentCategoryIndex);

	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	console.log('🚀 ~ Question ~ currentQuestionIndex:', currentQuestionIndex);
	const currentCategory = questions[currentCategoryIndex];

	const currentQuestion = currentCategory?.questions[currentQuestionIndex];
	// console.log('🚀 ~ Question ~ currentQuestion:', currentQuestion);

	//const isSelected = selectedValues[currentQuestion.name];
	const isSelected = selectedValues.find(
		(obj) => obj && obj.hasOwnProperty(currentQuestion?.name)
	);
	let keys;
	if (isSelected) {
		keys = Object.keys(isSelected);
	}
	//	console.log('🚀 ~ Question ~ keys:', keys);

	// const Range = isSelected[keys][currentQuestionIndex];
	// console.log('🚀 ~ Question ~ Range :', Range);

	// // Find the index of a specific key, for example, "Automation"
	// let index = keys.indexOf(currentQuestion.name);
	// console.log("Index of 'Automation':", index);

	const navigate = useNavigate();

	// Calculate progress percentage
	const progressPercentage = Math.round(
		((currentQuestionIndex + 1) / currentCategory?.questions.length) * 100
	);

	// Update progress when moving to a new category or answering questions
	useEffect(() => {
		if (currentQuestionIndex === 0 && currentCategoryIndex > 0) {
			dispatch({ type: 'RESET_PROGRESS' });
		} else {
			// Update progress
			dispatch({ type: 'UPDATE_PROGRESS', payload: progressPercentage });
		}
		dispatch({ type: 'SET_CURRENT_CATEGORY', payload: { currentCategory } });
	}, [
		currentCategoryIndex,
		currentQuestionIndex,
		dispatch,
		progressPercentage,
	]);

	// useEffect(() => {
	// 	const handleScroll = (event) => {
	// 		if (event.deltaY < 0) {
	// 			// Scrolling up
	// 			setTimeout(() => {
	// 				handleBackQuestion();
	// 			}, 500);

	// 			setScrollAnimation(true);
	// 		} else {
	// 			// Scrolling down
	// 			handleForwardQuestion();
	// 			setScrollAnimation(true);
	// 		}

	// 		// Set timeout to reset scroll direction after 500ms
	// 		setTimeout(() => {
	// 			setScrollAnimation(false);
	// 		}, 500);
	// 	};

	// 	window.addEventListener('wheel', handleScroll);

	// 	return () => {
	// 		window.removeEventListener('wheel', handleScroll);
	// 	};
	// }, [currentQuestionIndex, currentCategoryIndex, scrollAnimation]);

	// useEffect(() => {
	// 	const handleTouchPadScroll = (event) => {
	// 		// Check if this is a touchpad scroll event
	// 		if (event.deltaMode === 0) {
	// 			const deltaY = event.deltaY;

	// 			if (deltaY < 0) {
	// 				// Scrolling up
	// 				setTimeout(() => {
	// 					handleBackQuestion();
	// 				}, 500);
	// 				setScrollAnimation(false);
	// 				setScrollDirection('down');
	// 			} else {
	// 				// Scrolling down
	// 				handleForwardQuestion();
	// 				setScrollAnimation(false);
	// 			}

	// 			// Set timeout to reset scroll direction after 500ms
	// 			setTimeout(() => {
	// 				setScrollAnimation(true);
	// 				setScrollDirection('up');
	// 			}, 500);
	// 		}
	// 	};

	// 	window.addEventListener('wheel', handleTouchPadScroll);

	// 	return () => {
	// 		window.removeEventListener('wheel', handleTouchPadScroll);
	// 	};
	// }, [currentQuestionIndex, currentCategoryIndex, scrollAnimation]);

	const handleNext = (Id) => {
		const questionId = currentQuestion.id;

		// if (currentQuestion?.type === 'Simple') {
		// 	if (
		// 		currentCategory?.name === 'Automation' &&
		// 		currentQuestionIndex === currentCategory?.questions.length - 1
		// 	) {
		// 		setScrollAnimation(true);
		// 		setAnimationActive(false);
		// 	} else {
		// 		setScrollAnimation(false);
		// 		setAnimationActive(true);
		// 	}
		// }

		// if (currentQuestion?.type !== 'Simple') {
		// 	setRangeButton(true);
		// }
		setFirstQuestion(true);

		setTimeout(() => {
			setScrollAnimation(true);
		}, 300);

		// setTimeout(() => {
		// 	setAnimationActive(false);
		// }, 1000);

		const answerId = Id;

		const userId = UUID;
		const payload = {
			question: questionId,
			answer: answerId,
			user: userId,
		};

		fetch(`${process.env.REACT_APP_BASE_URL}/survey/user_response/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				// Handle response data if needed
			})
			.catch((error) => {
				console.error('Error posting user response:', error);
			});

		if (currentQuestion?.type === 'Simple') {
			if (currentQuestionIndex < currentCategory.questions.length - 1) {
				setCurrentQuestionIndex(currentQuestionIndex + 1);
			} else {
				if (currentCategoryIndex < questions.length - 1) {
					setCurrentCategoryIndex(currentCategoryIndex + 1);
					setCurrentQuestionIndex(0);
					dispatch({
						type: 'SET_CURRENT_CATEGORY_INDEX',
						payload: currentCategoryIndex + 1,
					});
				}
			}

			// if (currentCategory?.name !== 'Automation') {
			// 	if (currentQuestionIndex === currentCategory?.questions.length - 1) {
			// 		dispatch({ type: 'RESET_SELECTED_VALUES' });
			// 	}
			// }
		}
		if (
			currentCategory?.name === 'Automation' &&
			currentQuestionIndex === currentCategory?.questions.length - 1
		) {
			setNextButton(false);
			setBackButtonClicked(true);
		}
	};

	const handleRangeNext = () => {
		setScrollAnimation(false);

		setTimeout(() => {
			setScrollAnimation(true);
		}, 300);

		if (currentQuestionIndex < currentCategory.questions.length - 1) {
			setCurrentQuestionIndex(currentQuestionIndex + 1);
		} else {
			if (currentCategoryIndex < questions.length - 1) {
				setCurrentCategoryIndex(currentCategoryIndex + 1);
				setCurrentQuestionIndex(0);
				dispatch({
					type: 'SET_CURRENT_CATEGORY_INDEX',
					payload: currentCategoryIndex + 1,
				});
			}
		}

		setRangeButton(false);
	};

	const handleNextCategory = async () => {
		if (currentCategory?.name === 'Automation') {
			// try {
			// 	const userId = UUID;
			// 	const payload = {
			// 		user: userId,
			// 	};

			// 	const response = await fetch(
			// 		`${process.env.REACT_APP_BASE_URL}/survey/user_score_total/`,
			// 		{
			// 			method: 'POST',
			// 			headers: {
			// 				'Content-Type': 'application/json',
			// 			},
			// 			body: JSON.stringify(payload),
			// 		}
			// 	);

			// 	if (!response.ok) {
			// 		throw new Error('Network response was not ok');
			// 	}

			// 	const data = await response.json();
			// 	const reportId = data.report_uuid;
			// 	const nextUrl = `/report/${reportId}`;
			// 	navigate(nextUrl);
			// } catch (error) {
			// 	console.error('Error posting user response:', error);
			// }

			const nextUrl = '/emailform';
			navigate(nextUrl);
		} else {
			if (currentCategoryIndex < questions.length - 1) {
				setCurrentCategoryIndex(currentCategoryIndex + 1);
				setCurrentQuestionIndex(0);
			}
			//	dispatch({ type: 'RESET_SELECTED_VALUES' });
			setFirstQuestion(false);
		}
	};

	const handleForwardQuestion = () => {
		if (selectedValues.length != 0) {
			const lastIndex = selectedValues.length - 1;
			console.log('🚀 ~ handleForwardQuestion ~ lastIndex:', lastIndex);
			setCurrentQuestionIndex(lastIndex);
			setBackButtonClicked(false);
		}
	};

	const handleBackQuestion = () => {
		if (currentQuestionIndex > 0) {
			// If there are previous questions in the current category, move to the previous question
			setBackButtonClicked(true);
			setNextButton(false);
			setCurrentQuestionIndex(currentQuestionIndex - 1);
		} else if (currentCategoryIndex > 0) {
			// If there are no previous questions in the current category, move to the previous category
			setBackButtonClicked(true);
			setNextButton(false);
			setCurrentCategoryIndex(currentCategoryIndex - 1);
			setCurrentQuestionIndex(
				questions[currentCategoryIndex - 1].questions.length - 1
			);
			dispatch({
				type: 'SET_CURRENT_CATEGORY_INDEX',
				payload: currentCategoryIndex - 1,
			});
			//dispatch({ type: 'RESET_SELECTED_VALUES' });
		}
	};

	const handleBackCategory = () => {
		if (currentCategoryIndex > 0) {
			setCurrentCategoryIndex(currentCategoryIndex - 1);
			setCurrentQuestionIndex(0);
			//	dispatch({ type: 'RESET_SELECTED_VALUES' });
		}
	};

	//store the value in reducer
	const handleChange = (value, name) => {
		console.log('🚀 ~ handleChange ~ value:', value);
		if (currentQuestion?.type !== 'Simple') {
			setRangeButton(true);
		}
		dispatch({
			type: 'SELECT_VALUE',
			payload: { questionIndex: currentQuestionIndex, value, name },
		});
	};

	const handleChangeSlider = (value) => {
		console.log('🚀 ~ handleChangeSlider ~ value:', value);
		if (currentQuestion?.type !== 'Simple') {
			setRangeButton(true);
		}

		dispatch({
			type: 'STORE_SLIDER_VALUE',
			payload: value,
		});
	};

	const handleRangeSlider = (value) => {
		console.log('🚀 ~ handlranfnemldlSlider ~ value:', value);

		dispatch({
			type: 'STORE_RANGE_VALUE',
			payload: value,
		});
	};

	const handleChangeAndMoveNext = (id, name) => {
		handleChange(id, name);

		if (currentQuestion?.type === 'Simple') {
			if (
				currentCategory?.name === 'Automation' &&
				currentQuestionIndex === currentCategory?.questions.length - 1
			) {
				setAnimationActive(false);
			} else {
				setAnimationActive(true);
			}
			setTimeout(() => {
				handleNext(id);
			}, 300);
		} else {
			handleNext(id);
		}

		setTimeout(() => {
			setAnimationActive(false);
		}, 600);
	};

	// const handleForwardClick = () => {
	// 	if (
	// 		selectedValues &&
	// 		selectedValues.length !== 0 &&
	// 		selectedValues[forwardCurrentIndex] &&
	// 		selectedValues[forwardCurrentIndex][
	// 			Object.keys(selectedValues[forwardCurrentIndex])
	// 		].length > 0
	// 	) {
	// 		const lastIndex = selectedValues[forwardCurrentIndex];
	// 		console.log('🚀 ~ handleForwardQuestion ~ lastIndex:', lastIndex);

	// 		if (lastIndex) {
	// 			let name = Object.keys(lastIndex);
	// 			console.log('🚀 ~ handleForwardClick ~ name:', name);
	// 			const results = lastIndex[name].length - 1;
	// 			console.log('🚀 ~ handleForwardClick ~ results:', results);

	// 			setCurrentCategoryIndex(forwardCurrentIndex);
	// 			setCurrentQuestionIndex(results);
	// 			setBackButtonClicked(false);
	// 			setNextButton(true);
	// 		}
	// 	} else {
	// 		const newForwardIndex =
	// 			forwardCurrentIndex > 0 ? forwardCurrentIndex - 1 : 0;

	// 		console.log(
	// 			'🚀 ~ handleForwardClick ~ newForwardIndex:',
	// 			newForwardIndex
	// 		);
	// 		const lastIndex = selectedValues[newForwardIndex];

	// 		if (lastIndex) {
	// 			let name = Object.keys(lastIndex);
	// 			const results = lastIndex[name].length - 1;

	// 			setCurrentCategoryIndex(newForwardIndex);
	// 			setCurrentQuestionIndex(results);
	// 			setBackButtonClicked(false);
	// 			setNextButton(true);
	// 		}
	// 	}
	// };

	const handleNextQuestion = () => {
		if (currentQuestionIndex < currentCategory.questions.length - 1) {
			setCurrentQuestionIndex(currentQuestionIndex + 1);
			const lastIndex = selectedValues[forwardCurrentIndex];
			console.log('🚀 ~ handleForwardQuestion ~ lastIndex:', lastIndex);
			if (lastIndex) {
				let name = Object.keys(lastIndex);
				console.log('🚀 ~ handleForwardClick ~ name:', name);
				const results = lastIndex[name].length - 1;
				console.log('🚀 ~ handleForwardClick ~ results:', results);
				if (currentQuestionIndex >= results) {
					setBackButtonClicked(false);
				}
			}
		} else {
			if (currentCategoryIndex < questions.length - 1) {
				setCurrentCategoryIndex(currentCategoryIndex + 1);
				setCurrentQuestionIndex(0);
				dispatch({
					type: 'SET_CURRENT_CATEGORY_INDEX',
					payload: currentCategoryIndex + 1,
				});
			}
		}
		setRangeButton(false);
	};

	useEffect(() => {
		if (
			currentCategory?.name === 'Automation' &&
			currentQuestionIndex === currentCategory?.questions.length
		) {
			dispatch({
				type: 'STORE_CONFIRMATION_VALUE',
				payload: false,
			});
		}
	}, [currentCategory, currentQuestionIndex]);

	// useEffect(() => {
	// 	const handleBeforeUnload = (e) => {
	// 		e.preventDefault();
	// 		// For modern browsers
	// 		e.returnValue = '';
	// 		// For older browsers
	// 		return 'Are you sure you want to leave? Your changes may not be saved.';
	// 	};

	// 	const handlePopState = (event) => {
	// 		if (event.state) {
	// 			// Do your code here when browser back button is clicked
	// 			// alert('Please fill the question!');
	// 			setShowDialog(true);
	// 		}
	// 	};

	// 	window.addEventListener('beforeunload', handleBeforeUnload);
	// 	window.addEventListener('popstate', handlePopState);

	// 	return () => {
	// 		window.removeEventListener('beforeunload', handleBeforeUnload);
	// 		window.removeEventListener('popstate', handlePopState);
	// 	};
	// }, []);

	// useEffect(() => {
	// 	// Push two states to ensure that the popstate event is triggered when navigating back from this component
	// 	window.history.pushState(
	// 		{ name: 'browserBack' },
	// 		'on browser back click',
	// 		window.location.href
	// 	);
	// 	window.history.pushState(
	// 		{ name: 'browserBack' },
	// 		'on browser back click',
	// 		window.location.href
	// 	);
	// }, []);

	return (
		// <Slide direction={'down'} in={scrollAnimation} mountOnEnter unmountOnExit>
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
			}}
		>
			{/* {showDialog && <AlertDialog state={true} setShowDialog={setShowDialog} />} */}
			{/* {currentQuestionIndex !== 0 && scrollAnimation ? (
				<Stack spacing={6} sx={{ width: '846px' }}>
					<Box
						sx={{
							display: 'flex',

							flexDirection: 'column',

							alignItems: 'center',
							justifyContent: 'space-around',
						}}
					>
						<Skeleton
							variant='h3'
							width={120}
							height={32}
							sx={{ borderRadius: '25px', m: '20px' }}
						/>
						<Skeleton variant='h3' width={500} height={60} />
					</Box>

					<Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
						<Skeleton variant='rectangular' width={352} height={178} />
						<Skeleton variant='rectangular' width={352} height={178} />
					</Box>
				</Stack>
			) : ( */}

			{/* <div
				className={scrollAnimation ? ' w3-animate-bottom' : ''}
				//className={`question-container ${showNextQuestion ? 'slide-in' : ''}`}
				//sx={{}}
			> */}

			<Box
				maxWidth={'full'}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					width: '846px',
					position: 'relative',
					animationName: animationActive ? 'slide' : 'none',
					animationDuration: '0.6s',
					animationIterationCount: '1',
					animationTimingFunction: 'ease-in-out',

					'@keyframes slide': {
						from: {
							top: 0,
						},
						to: {
							top: '800px',
						},
					},
				}}
			>
				<Box
					sx={{
						border: '1px solid #226CE0',
						borderRadius: '1000px',
						bgcolor: '#F5F5F5',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '8px',
						gap: '10px',
						mt: '40px',
					}}
				>
					<Typography
						sx={{
							color: '#26282C',
							fontFamily: 'Figtree',
							lineHeight: '19.2px',
							letterSpacing: '2px',
							fontSize: '12px',
							fontWeight: '300px',
						}}
					>
						QUESTION
					</Typography>
					<span
						style={{
							fontSize: '12px',
							fontFamily: 'Figtree',
							lineHeight: '19.2px',
							letterSpacing: '2px',
							fontWeight: 'bold',
						}}
					>
						{` ${currentQuestionIndex + 1}/${
							questions[currentCategoryIndex]?.questions.length
						}`}
					</span>
				</Box>

				<Box
					sx={{
						display: 'flex',
						mt: '24px',
					}}
				>
					<Typography
						sx={{
							fontWeight: '600px',
							fontSize: '24px',
							fontFamily: 'Figtree',
						}}
					>
						{currentQuestion?.question}
					</Typography>

					{/* <img
							src={questionicon}
							style={{ marginLeft: '2px' }}
							alt='question-image'
						/> */}
				</Box>

				<Box
					sx={{
						display: 'flex',
						mt: '38px',
					}}
				>
					{currentQuestion?.type === 'Simple' &&
						currentQuestion?.answers.map((answer) => (
							<Box
								key={answer.id}
								sx={{
									width: '352px',
									height: '178px',
									border:
										//selectedValues[currentQuestionIndex] === answer.id
										// isSelected &&
										// isSelected[currentQuestionIndex] === answer.id

										isSelected &&
										isSelected[keys] &&
										isSelected[keys][currentQuestionIndex] === answer.id
											? '1.5px solid #FF5E29'
											: '1.5px solid #D8D8D9',
									ml:
										answer.id === currentQuestion?.answers[0]?.id
											? '0px'
											: '24px',

									cursor: 'pointer',
								}}
								onClick={() =>
									handleChangeAndMoveNext(answer.id, currentQuestion.name)
								}
							>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'end',
										}}
									>
										<CustomRadio
											checked={
												//selectedValues[currentQuestionIndex] === answer.id
												// isSelected &&
												// isSelected[currentQuestionIndex] === answer.id
												isSelected &&
												isSelected[keys] &&
												isSelected[keys][currentQuestionIndex] === answer.id
											}
											value={answer.id}
											name='radio-button-demo'
											inputProps={{ 'aria-label': answer.answer }}
										/>
									</Box>

									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<img
											// src={
											// 	answer.answer === 'Yes'
											// 		? isSelected &&
											// 		  isSelected[keys] &&
											// 		  isSelected[keys][currentQuestionIndex] ===
											// 				answer.id
											// 			? handuporangeicon
											// 			: handupgrayicon
											// 		: answer.answer === 'Text only'
											// 		? isSelected &&
											// 		  isSelected[keys] &&
											// 		  isSelected[keys][currentQuestionIndex] ===
											// 				answer.id
											// 			? textonly
											// 			: textonly
											// 		: answer.answer === 'NO'
											// 		? isSelected &&
											// 		  isSelected[keys] &&
											// 		  isSelected[keys][currentQuestionIndex] ===
											// 				answer.id
											// 			? handdownorangeicon
											// 			: handdowngrayicon
											// 		: isSelected &&
											// 		  isSelected[keys] &&
											// 		  isSelected[keys][currentQuestionIndex] ===
											// 				answer.id
											// 		? designed
											// 		: designed
											// }

											src={
												answer.answer === 'Yes'
													? isSelected &&
													  isSelected[keys] &&
													  isSelected[keys][currentQuestionIndex] === answer.id
														? handuporangeicon
														: handupgrayicon
													: answer.answer === 'Text only'
													? isSelected &&
													  isSelected[keys] &&
													  isSelected[keys][currentQuestionIndex] === answer.id
														? textonly
														: textonlywhite
													: answer.answer === 'Design'
													? isSelected &&
													  isSelected[keys] &&
													  isSelected[keys][currentQuestionIndex] === answer.id
														? designedOrange
														: designed
													: isSelected &&
													  isSelected[keys] &&
													  isSelected[keys][currentQuestionIndex] === answer.id
													? handdownorangeicon
													: handdowngrayicon
											}
											alt='question-image'
											style={{
												width: '76.09px',
												height: '52.48px',
											}}
										/>

										<Typography
											sx={{
												fontFamily: 'Figtree',
												fontWeight: '600px',
												lineHeight: '18px',
												mt: '20px',
											}}
										>
											{answer.answer}
										</Typography>
									</Box>
								</Box>
							</Box>
						))}

					{/* slider */}

					{currentQuestion?.type === 'Range more' && (
						<Box
							sx={{
								width: '786px',
								mt: '38px',
								height: '100px',
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<PrettoSlider
								valueLabelDisplay='auto'
								aria-label='pretto slider'
								defaultValue={sliderValuesSet === '' ? 0 : sliderValuesSet}
								min={0}
								max={10000}
								onChange={(event, newValue) => {
									handleChangeSlider(newValue);
								}}
								onClick={(event, newValue) => {
									const selectedAnswer = currentQuestion?.answers?.find(
										(answer) => {
											return newValue >= 1000
												? answer.answer === 'Greater than 1000'
												: answer.answer === 'Smaller than 1000';
										}
									);
									if (selectedAnswer) {
										handleChangeAndMoveNext(
											selectedAnswer.id,
											currentQuestion.name
										);
									}
								}}
							/>

							<Box sx={{ display: 'flex' }}>
								<Box
									sx={{
										mt: '2px',
										height: '12px',
										border: '1px solid #D8D8D9',
										ml: '3px',
									}}
								/>
								{Array.from({ length: 10 }, (_, index) => (
									<Box
										key={index}
										sx={{
											ml: '20px',
											mt: '2px',
											height: '9px',
											border: '1px solid #E9E9EA',
										}}
									/>
								))}
								<Box
									sx={{
										ml: '20px',
										mt: '2px',
										height: '12px',
										border: '1px solid #D8D8D9',
									}}
								/>
								{Array.from({ length: 10 }, (_, index) => (
									<Box
										key={index}
										sx={{
											ml: '20px',
											mt: '2px',
											height: '9px',
											border: '1px solid #E9E9EA',
										}}
									/>
								))}
								<Box
									sx={{
										ml: '20px',
										mt: '2px',
										height: '12px',
										border: '1px solid #D8D8D9',
									}}
								/>
								{Array.from({ length: 12 }, (_, index) => (
									<Box
										key={index}
										sx={{
											ml: '20px',
											mt: '2px',
											height: '9px',
											border: '1px solid #E9E9EA',
										}}
									/>
								))}
								<Box
									sx={{
										ml: '20px',
										mt: '2px',
										height: '12px',
										border: '1px solid #D8D8D9',
									}}
								/>
							</Box>
							<Box
								sx={{
									pt: '12px',
									display: 'flex',
									// justifyContent: 'space-between',

									ml: '2px',
								}}
							>
								<Typography
									sx={{
										fontSize: '16px',
										fontWeight: '400px',
										lineHeight: '16px',
										color: '#939395',
										ml: '',
									}}
								>
									0
								</Typography>
								<Typography
									sx={{
										fontSize: '16px',
										fontWeight: '400px',
										lineHeight: '16px',
										color: '#939395',
										ml: '205px',
									}}
								>
									3000
								</Typography>
								<Typography
									sx={{
										fontSize: '16px',
										fontWeight: '400px',
										lineHeight: '16px',
										color: '#939395',
										ml: '200px',
									}}
								>
									6000
								</Typography>

								<Typography
									sx={{
										fontSize: '16px',
										fontWeight: '400px',
										lineHeight: '16px',
										color: '#939395',
										ml: '250px',
									}}
								>
									{`>`}10000
								</Typography>
							</Box>
						</Box>
					)}

					{currentQuestion?.type === 'Range' && (
						<Box
							sx={{
								width: '786px',
								mt: '38px',
								height: '100px',
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<PrettoSlider
								valueLabelDisplay='auto'
								aria-label='pretto slider'
								defaultValue={
									// isSelected &&
									// isSelected[keys] &&
									// isSelected[keys][currentQuestionIndex] === 38
									// 	? '25'
									// 	: '0'
									sliderValuesRange === '' ? 0 : sliderValuesRange
								}
								min={0}
								max={5}
								onChange={(event, newValue) => {
									handleRangeSlider(newValue);
								}}
								onClick={(event, newValue) => {
									const selectedAnswer = currentQuestion?.answers?.find(
										(answer) => {
											return newValue > 0
												? answer.answer === 'Zero'
												: answer.answer === 'One';
											// answer.answer === 'More than one ';
										}
									);
									if (selectedAnswer) {
										handleChangeAndMoveNext(
											selectedAnswer.id,
											currentQuestion.name
										);
									}
								}}
							/>

							<Box sx={{ display: 'flex' }}>
								<Box
									sx={{
										mt: '2px',
										height: '12px',
										border: '1px solid #D8D8D9',
										ml: '3px',
									}}
								/>
								{Array.from({ length: 10 }, (_, index) => (
									<Box
										key={index}
										sx={{
											ml: '20px',
											mt: '2px',
											height: '9px',
											border: '1px solid #E9E9EA',
										}}
									/>
								))}
								<Box
									sx={{
										ml: '20px',
										mt: '2px',
										height: '12px',
										border: '1px solid #D8D8D9',
									}}
								/>
								{Array.from({ length: 10 }, (_, index) => (
									<Box
										key={index}
										sx={{
											ml: '20px',
											mt: '2px',
											height: '9px',
											border: '1px solid #E9E9EA',
										}}
									/>
								))}
								<Box
									sx={{
										ml: '20px',
										mt: '2px',
										height: '12px',
										border: '1px solid #D8D8D9',
									}}
								/>
								{Array.from({ length: 12 }, (_, index) => (
									<Box
										key={index}
										sx={{
											ml: '20px',
											mt: '2px',
											height: '9px',
											border: '1px solid #E9E9EA',
										}}
									/>
								))}
								<Box
									sx={{
										ml: '20px',
										mt: '2px',
										height: '12px',
										border: '1px solid #D8D8D9',
									}}
								/>
							</Box>
							<Box
								sx={{
									pt: '12px',
									display: 'flex',
									// justifyContent: 'space-between',
									ml: '2px',
								}}
							>
								<Typography
									sx={{
										fontSize: '16px',
										fontWeight: '400px',
										lineHeight: '16px',
										color: '#939395',
										ml: '',
									}}
								>
									0
								</Typography>

								<Typography
									sx={{
										fontSize: '16px',
										fontWeight: '400px',
										lineHeight: '16px',
										color: '#939395',
										ml: '145px',
									}}
								>
									1
								</Typography>
								<Typography
									sx={{
										fontSize: '16px',
										fontWeight: '400px',
										lineHeight: '16px',
										color: '#939395',
										ml: '145px',
									}}
								>
									2
								</Typography>
								<Typography
									sx={{
										fontSize: '16px',
										fontWeight: '400px',
										lineHeight: '16px',
										color: '#939395',
										ml: '150px',
									}}
								>
									3
								</Typography>
								<Typography
									sx={{
										fontSize: '16px',
										fontWeight: '400px',
										lineHeight: '16px',
										color: '#939395',
										ml: '145px',
									}}
								>
									4
								</Typography>

								<Typography
									sx={{
										fontSize: '16px',
										fontWeight: '400px',
										lineHeight: '16px',
										color: '#939395',
										ml: '130px',
									}}
								>
									{`>=`}5
								</Typography>
							</Box>
						</Box>
					)}
				</Box>
				{/* {currentQuestionIndex === currentCategory?.questions.length - 1 ? (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								mt: '24px',
								width: '736px',
								height: '40px',
							}}
						>
							<Box>
								<Button	currentCategory?.name === questions[0]?.name
									sx={{
										fontFamily: 'Figtree',
										fontWeight: '600px',
										fontSize: '14px',
										height: '40px',
										lineHeight: '16px',
										textTransform: 'none',
										color: '#A3B0B3',
									}}
									onClick={handleBackQuestion}
									startIcon={<ArrowBackIcon />}
									// disabled={
									// 	currentCategory?.name === questions[0]?.name &&
									// 	currentCategory?.questions[0] == questions[0]?.questions[0]
									// }
									disabled={!firstQuestion}
								>
									Previous
								</Button>
								{questions[currentCategoryIndex]?.name !== 'Sends' && (
									<Button
										sx={{
											fontFamily: 'Figtree',
											fontWeight: '600px',
											fontSize: '14px',
											height: '40px',
											lineHeight: '16px',
											textTransform: 'none',
											color: '#FF5E29',
											ml: '4px',
										}}
										onClick={handleBackCategory}
										// startIcon={<ArrowBackIcon />}
										disabled={questions[currentCategoryIndex]?.name === 'Sends'}
									>
										{`Go Back ${previousCategoryName}`}
									</Button>
								)}
							</Box>

							<Button
								variant='contained'
								sx={{
									fontFamily: 'Figtree',
									fontWeight: '600px',
									fontSize: '14px',
									width: '200px',
									height: '40px',
									borderRadius: '5px',
									textTransform: 'none',
								}}
								endIcon={<ArrowForwardIcon />}
								style={{
									background:
										currentCategory?.name === 'Automation'
											? '#4285F4'
											: '#FF5E29',
								}}
								onClick={handleNextCategory}
								disabled={
									selectedValues.length !== currentCategory.questions.length
								}
							>
								{currentCategory?.name === 'Automation'
									? 'Finish & Get Report'
									: 'Go to Next step'}
							</Button>
						</Box>
					) : ( */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						mt: '24px',
						width: '736px',
						height: '40px',
					}}
				>
					{currentCategory?.name === 'Sends' && currentQuestionIndex === 0 ? (
						''
					) : (
						<Button
							sx={{
								fontFamily: 'Figtree',
								fontWeight: '600px',
								fontSize: '14px',
								height: '40px',
								lineHeight: '16px',
								textTransform: 'none',
								color: '#A3B0B3',
							}}
							onClick={handleBackQuestion}
							startIcon={<ArrowBackIcon />}
							disabled={!firstQuestion}
						>
							Previous
						</Button>
					)}

					{/* {currentQuestion?.type !== 'Simple' && (
							<Button
								sx={{
									fontFamily: 'Figtree',
									fontWeight: '600px',
									fontSize: '14px',
									height: '40px',
									lineHeight: '16px',
									textTransform: 'none',
									color: 'white',
									borderRadius: '1000px',
								}}
								disabled={!rangeButton}
								onClick={handleRangeNext}
								style={{
									background: '#FF5E29',
								}}

								// disabled={
								// 	currentCategory?.name === questions[0]?.name &&
								// 	currentCategory?.questions[0] == questions[0]?.questions[0]
								// }
							>
								Next
							</Button>
						)} */}

					{/* {nextButton ? (
							<Button
								variant='contained'
								sx={{
									fontFamily: 'Figtree',
									fontWeight: '600px',
									fontSize: '14px',

									borderRadius: '5px',
									textTransform: 'none',
								}}
								endIcon={<ArrowForwardIcon />}
								style={{
									background: '#FF5E29',
								}}
								disabled={!rangeButton}
								onClick={handleRangeNext}
							>
								Next
							</Button>
						) : (
							<Button
								variant='contained'
								sx={{
									fontFamily: 'Figtree',
									fontWeight: '600px',
									fontSize: '14px',

									borderRadius: '5px',
									textTransform: 'none',
								}}
								endIcon={<ArrowForwardIcon />}
								style={{
									background: '#FF5E29',
								}}
								onClick={
									currentCategory?.name === 'Automation' &&
									currentQuestionIndex === currentCategory?.questions.length - 1
										? handleNextCategory
										: handleForwardClick
								}
								disabled={
									currentCategory?.name === 'Automation' &&
									currentQuestionIndex === currentCategory?.questions.length - 1
										? isSelected[keys].length !==
										  currentCategory.questions.length
										: !backButtonClicked
								}
							>
								{currentCategory?.name === 'Automation' &&
								currentQuestionIndex === currentCategory?.questions.length - 1
									? 'Finish & Get Report'
									: 'Forward'}
							</Button>
						)} */}

					{/* {rangeButton && (
							<Button
								variant='contained'
								sx={{
									fontFamily: 'Figtree',
									fontWeight: '600px',
									fontSize: '14px',

									borderRadius: '5px',
									textTransform: 'none',
								}}
								endIcon={<ArrowForwardIcon />}
								style={{
									background: '#FF5E29',
								}}
								onClick={handleRangeNext}
							>
								Next
							</Button>
						)} */}

					{!backButtonClicked ? (
						rangeButton && (
							<Button
								variant='contained'
								sx={{
									fontFamily: 'Figtree',
									fontWeight: '600px',
									fontSize: '14px',
									borderRadius: '5px',
									textTransform: 'none',
								}}
								endIcon={<ArrowForwardIcon />}
								style={{
									background: '#FF5E29',
								}}
								onClick={handleRangeNext}
							>
								Next
							</Button>
						)
					) : (
						<Button
							variant='contained'
							sx={{
								fontFamily: 'Figtree',
								fontWeight: '600px',
								fontSize: '14px',
								borderRadius: '5px',
								textTransform: 'none',
							}}
							endIcon={<ArrowForwardIcon />}
							style={{
								background: '#FF5E29',
							}}
							onClick={
								currentCategory?.name === 'Automation' &&
								currentQuestionIndex === currentCategory?.questions.length - 1
									? handleNextCategory
									: handleNextQuestion
							}
							disabled={
								currentCategory?.name === 'Automation' &&
								currentQuestionIndex === currentCategory?.questions.length - 1
									? isSelected[keys].length !== currentCategory.questions.length
									: !backButtonClicked
							}
						>
							{currentCategory?.name === 'Automation' &&
							currentQuestionIndex === currentCategory?.questions.length - 1
								? 'Finish & Get Report'
								: 'Next'}
						</Button>
					)}
				</Box>
				{/* )} */}
			</Box>

			{/* </div> */}
			{/* )} */}

			{/* <Box
					sx={{
						width: '10px',
						mt: '25px',
					}}
				>
					<QuestionBar progress={progress} />
				</Box> */}
		</Box>
		// </Slide>
	);
};

Question.propTypes = {
	questions: PropTypes.array.isRequired,
};

export default Question;
