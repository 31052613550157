export const questionReducer = (state, action) => {
	switch (action.type) {
		case 'SELECT_VALUE':
			const { questionIndex, value, name } = action.payload;

			// Copy the selected values array for the specific name
			const newSelectedValues = [...state.selectedValues];

			// Copy the object for the specific name, or initialize it if it doesn't exist
			const selectedValuesForName = {
				...(newSelectedValues.find((obj) => obj.hasOwnProperty(name)) || {
					[name]: [],
				}),
			};

			// Update the selected value at the specified question index
			selectedValuesForName[name][questionIndex] = value;

			// Add the updated or newly initialized object for the specific name to the newSelectedValues array
			const updatedSelectedValues = newSelectedValues.map((obj) =>
				obj.hasOwnProperty(name) ? selectedValuesForName : obj
			);

			// Update the state with the new selectedValues array
			return {
				...state,
				selectedValues: updatedSelectedValues,
			};

		case 'UPDATE_PROGRESS':
			return { ...state, progress: action.payload };

		case 'RESET_PROGRESS':
			return { ...state, progress: 0 };
		case 'FETCH_DATA_SUCCESS':
			return { ...state, data: action.payload, error: null };
		case 'FETCH_DATA_FAILURE':
			return { ...state, error: action.payload };
		case 'FETCH_REPORT_DATA_SUCCESS':
			return { ...state, reportData: action.payload, error: null };
		case 'SET_CURRENT_CATEGORY':
			const { currentCategory } = action.payload;
			return { ...state, currentCategory };
		case 'SET_CATEGORY_NAME':
			const { values } = action.payload;
			return {
				...state,
				selectedValues: values,
			};

		case 'RESET_SELECTED_VALUES':
			return {
				...state,
				selectedValues: [],
			};

		case 'SET_CURRENT_CATEGORY_INDEX':
			return {
				...state,
				forwardCurrentIndex: action.payload,
			};

		case 'STORE_INPUT_VALUE':
			return {
				...state,
				websiteName: action.payload,
			};
		case 'SUBMIT_FORM_SUCCESS':
			return {
				...state,
				formDataResponse: action.payload,
			};

		case 'STORE_SLIDER_VALUE':
			return {
				...state,
				sliderValuesSet: action.payload,
			};

		case 'RESET_STORE_SLIDER_VALUE':
			return {
				...state,
				sliderValuesSet: '',
			};

		case 'STORE_RANGE_VALUE':
			return {
				...state,
				sliderValuesRange: action.payload,
			};

		case 'RESET_STORE_RANGE_VALUE':
			return {
				...state,
				sliderValuesRange: '',
			};
		case 'STORE_LOCATION_VALUE':
			return {
				...state,
				locationValuesSet: action.payload,
			};
		case 'STORE_CONFIRMATION_VALUE':
			return {
				...state,
				showConfirmationBox: action.payload,
			};

		default:
			return state;
	}
};
