import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import backgroundimage from '../../assets/background.png';
import Header from '../questionnaire /Header';
import { useNavigate } from 'react-router-dom';
import Footer from '../questionnaire /Footer';
import { useQuestionContext } from '../../context/QuestionContext';
import PhoneInput from 'react-phone-number-input';

import './phoneinput.css';
import Cookies from 'universal-cookie';
import { isValidPhoneNumber } from 'react-phone-number-input';

const emailpage = true;
const cookie = new Cookies();

const industries = [
	'Animal & Pet',
	'Apparel, Fashion & Jewelry',
	'Attorneys, Law & Legal',
	'Arts & Entertainment',
	'Automotive (Sales & Rental)',
	'Beauty, Cosmetics & Personal Care',
	'Career & Employment',
	'E-commerce',
	'Education, Coaching & Instruction',
	'Farming, Agriculture & Sustainability',
	'Finance, Banks, Investments & Accounting',
	'Fitness, Supplements & Vitamins',
	'Health-Related, Wellness & Medical',
	'Healthcare',
	'House Decor, Furniture, Home Improvement',
	'Heavy Industry, Energy & Manufacturing',
	'Insurance',
	'Logistics & Transportation',
	'Marketing, Advertising, PR & Digital Services',
	'Pharma',
	'Professional Services & Contractors (Cleaners, HVAC, Plumbers, etc.)',
	'Real Estate',
	'Restaurants, Foods & Beverages',
	'SaaS, IT & Software',
	'Travel, Hotel & Tourism',
	'Other',
];
const emailForm = true;

function EmailForm() {
	const { state } = useQuestionContext();
	const { websiteName, locationValuesSet } = state;
	console.log('🚀 ~ EmailForm ~ locationValuesSet:', locationValuesSet);
	const existingUuid = cookie.get('uuid');

	const [formError, setFormErrors] = useState();

	const [loading, setLoading] = useState(false);

	const [formData, setFormData] = useState({
		uuid: existingUuid || '',
		fname: '',
		lname: '',
		website: websiteName,
		email: '',
		phone: '',
		industry: '',
		monthly_budget: '',
	});
	const [formValid, setFormValid] = useState(false);
	const navigate = useNavigate();
	const { dispatch } = useQuestionContext();

	const validateForm = () => {
		const errors = {};
		// Validate email
		if (!formData?.email) {
			errors.email = 'Email is required';
		} else if (!/^\S+@\S+\.\S+$/.test(formData?.email)) {
			errors.email = 'Invalid email address';
		}
		// Validate phone number
		if (!formData?.phone) {
			errors.phone = 'Phone number is required';
		} else if (isValidPhoneNumber(formData?.phone) === false) {
			errors.phone = 'Please enter a valid phone number';
		}
		// Validate URL
		if (!formData?.website) {
			errors.website = 'Website URL is required';
		} else if (
			!/^(?![a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:https?:\/\/)?(?:[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|localhost)(?:\/[^\s]*)?$/i.test(
				formData?.website
			)
		) {
			errors.website = 'Invalid URL.';
		}

		return errors;
	};
	const isFormValid = () => {
		for (const key in formData) {
			if (formData[key] === '') {
				return false;
			}
		}
		return true;
	};

	useEffect(() => {
		setFormValid(isFormValid());
	}, [formData]);

	const handleChange = (eventOrValue, name) => {
		let value;

		if (typeof eventOrValue === 'object' && eventOrValue.target) {
			const { value: inputValue } = eventOrValue.target;
			value = inputValue;
		} else {
			value = String(eventOrValue);
		}

		let updatedFormError = { ...formError };
		if (name === 'email' && /\S+@\S+/.test(value)) {
			delete updatedFormError.email;
		}
		if (name === 'phone' && isValidPhoneNumber(value)) {
			delete updatedFormError.phone;
		}
		if (
			name === 'website' &&
			/^(?![a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:https?:\/\/)?(?:[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|localhost)(?:\/[^\s]*)?$/i.test(
				value
			)
		) {
			delete updatedFormError.website;
		}
		setFormErrors(updatedFormError);

		const updatedFormData = { ...formData, [name]: value };
		setFormData(updatedFormData);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const errors = validateForm();
		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		setLoading(true);

		try {
			const combinedData = { ...formData, ...locationValuesSet };
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/profile/`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(combinedData),
				}
			);

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();

			dispatch({ type: 'SUBMIT_FORM_SUCCESS', payload: data });
		} catch (error) {
			console.error('Error posting user response:', error);
		}

		try {
			const payload = {
				user: existingUuid,
			};

			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/survey/user_score_total/`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}
			);

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.json();
			const reportId = data.report_uuid;
			const nextUrl = `/report/${reportId}`;
			navigate(nextUrl);
		} catch (error) {
			console.error('Error posting user response:', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box
			sx={{
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<Header emailForm={emailForm} />
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-around',
					alignItems: 'center',
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: 20,
						left: 0,
						right: 0,
						bottom: 0,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundImage: `url(${backgroundimage})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						height: 'auto',
						width: '100%',
						zIndex: -1,
					}}
				/>
				{/* <Box
					sx={{
						background: 'rgba(0, 0, 0, 0.32)',
						backdropFilter: 'blur(8px)',
						height: '85vh',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				> */}
				<Box
					sx={{
						borderRadius: '5px',
						p: '20px',

						bgcolor: 'white',
						position: 'relative',
						zIndex: 1,

						maxWidth: '520px',
						//boxShadow: '0px 0px 30px black',
						// boxShadow: ' #000000 0px 0px 30px',
						boxShadow: ' rgb(0 0 0 / 26%) 0px 0px 30px',
					}}
				>
					<form onSubmit={handleSubmit}>
						<Box>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Typography
									sx={{
										fontFamily: 'Figtree',
										fontWeight: '700px',
										fontSize: '24px',
										lineHeight: '33.6px',
									}}
								>
									<span style={{ fontWeight: 'bold', paddingLeft: '8px' }}>
										You're one step away:
									</span>{' '}
									fill the form <br />
									below to see the Mail Grader{' '}
									<span style={{ color: '#FF5E29' }}>results</span>
									...
								</Typography>
							</Box>

							<Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
								<FormControl fullWidth margin='dense'>
									<TextField
										label='Email'
										variant='outlined'
										id='email'
										name='email'
										value={formData.email}
										onChange={(e) => handleChange(e, 'email')}
									/>
									{formError?.email && (
										<div
											style={{
												color: 'red',
												marginLeft: '8px',
												marginTop: '8px',
											}}
										>
											{formError.email}
										</div>
									)}
								</FormControl>

								<FormControl fullWidth margin='dense' sx={{ ml: '6px' }}>
									<PhoneInput
										// flags={flags}
										defaultCountry='US'
										international='true'
										rules={isValidPhoneNumber}
										placeholder=' Phone '
										name='phone'
										value={formData.phone}
										onChange={(value) => handleChange(value, 'phone')}
									/>
									{formError?.phone && (
										<div
											style={{
												color: 'red',
												marginLeft: '8px',
												marginTop: '8px',
											}}
										>
											{formError.phone}
										</div>
									)}
								</FormControl>
							</Box>

							<Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
								<FormControl fullWidth margin='dense'>
									<TextField
										label=' First Name'
										variant='outlined'
										id='fname'
										name='fname'
										value={formData.fname}
										onChange={(e) => handleChange(e, 'fname')}
									/>
								</FormControl>
								<FormControl fullWidth margin='dense' sx={{ ml: '6px' }}>
									<TextField
										label='Last Name'
										variant='outlined'
										id='lname'
										name='lname'
										value={formData.lname}
										onChange={(e) => handleChange(e, 'lname')}
									/>
								</FormControl>
							</Box>
							<Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
								<FormControl fullWidth margin='dense'>
									<TextField
										label='Website'
										variant='outlined'
										id='website'
										name='website'
										value={formData.website}
										onChange={(e) => handleChange(e, 'website')}
									/>
									{formError?.website && (
										<div
											style={{
												color: 'red',
												marginLeft: '8px',
												marginTop: '8px',
											}}
										>
											{formError.website}
										</div>
									)}
								</FormControl>
								<FormControl fullWidth margin='dense' sx={{ ml: '6px' }}>
									<TextField
										label='Industry'
										variant='outlined'
										select
										id='industry'
										name='industry'
										value={formData.industry}
										onChange={(e) => handleChange(e, 'industry')}
									>
										{industries.map((industry) => (
											<MenuItem key={industry} value={industry}>
												{industry}
											</MenuItem>
										))}
									</TextField>
								</FormControl>

								{/* <FormControl fullWidth margin='dense' sx={{ ml: '6px' }}>
										<TextField
											label='Annual Revenue'
											variant='outlined'
											select
											id='revenue'
											name='revenue'
											value={formData.revenue}
											onChange={(e) => handleChange(e, 'revenue')}
										>
											<MenuItem value='0-100k'>$0 to $500 thousand</MenuItem>
											<MenuItem value='100k-500k'>
												$500 thousand to $1 million
											</MenuItem>
											<MenuItem value='500k-1m'>$1 to $3 million</MenuItem>
											<MenuItem value='1m+'>$3 to $10 million</MenuItem>
										</TextField>
									</FormControl> */}
							</Box>
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<FormControl fullWidth margin='dense'>
									<TextField
										label='Monthly Market Budget'
										variant='outlined'
										select
										id='market'
										name='market'
										value={formData.monthly_budget}
										onChange={(e) => handleChange(e, 'monthly_budget')}
									>
										<MenuItem value='Under $750'>Under $750</MenuItem>
										<MenuItem value='$750 to $1,500'>$750 to $1,500</MenuItem>
										<MenuItem value='$1,500 to $5,000'>
											$1,500 to $5,000
										</MenuItem>
										<MenuItem value='$5,000 to $10,000'>
											$5,000 to $10,000
										</MenuItem>
										<MenuItem value='$10,000 to $25,000'>
											$10,000 to $25,000
										</MenuItem>
										<MenuItem value='$25,000 to $50,000'>
											$25,000 to $50,000
										</MenuItem>
										<MenuItem value='$50,000 to $100,000'>
											$50,000 to $100,000
										</MenuItem>
										<MenuItem value='Above $100,000'>Above $100,000</MenuItem>
									</TextField>
								</FormControl>
							</Box>
							<Box
								sx={{
									display: 'flex',

									flexDirection: 'column',
									alignItems: 'center',
									mt: '8px',
								}}
							>
								<Typography
									sx={{
										fontFamily: 'Figtree',
										fontWeight: '400px',
										fontSize: '11px',
										lineHeight: '16px',
										color: '#BEBFC0',

										//width: '468px',
									}}
								>
									By clicking the button below, you consent for NP Digital and
									partners to use automated technology, including pre-recorded
									messages, cell phones and texts, and email to contact you at
									the number and email address provided. This includes if the
									number is currently on any Do Not Call Lists. This consent is
									not required to make a purchase. Privacy Policy.
								</Typography>
								<Button
									fullWidth
									variant='contained'
									type='submit'
									sx={{
										bgcolor: '#4285F4',
										'&:disabled': {
											bgcolor: '#4285F4',
										},

										mt: '8px',
										height: '50px',
									}}
									disabled={!formValid}
								>
									{loading ? <CircularProgress /> : 'See My Results NOW!'}
								</Button>
							</Box>
						</Box>
					</form>
				</Box>
				{/* </Box> */}
				{/* <Box
					sx={{
						position: 'relative',
						zIndex: 1,
					}}
				>
					<img src={formimage} alt='emailimage' style={{ maxwidth: '689px' }} />
				</Box> */}
			</Box>
			<Footer emailpage={emailpage} />
		</Box>

		// <Box
		// 	sx={{
		// 		height: '100vh',
		// 		display: 'flex',
		// 		flexDirection: 'column',
		// 		justifyContent: 'space-between',
		// 	}}
		// >
		// 	<Header emailForm={emailForm} />
		// 	<Box
		// 		sx={{
		// 			flexGrow: 1,
		// 			position: 'relative',
		// 			backgroundImage: `url(${backgroundimage})`,
		// 			backgroundPosition: 'center',
		// 			backgroundSize: 'cover',
		// 			backgroundRepeat: 'no-repeat',
		// 		}}
		// 	>
		// 		<Box
		// 			sx={{
		// 				display: 'flex',
		// 				justifyContent: 'center',
		// 				alignItems: 'center',
		// 				height: 'calc(80vh )',
		// 				py: '50px',
		// 			}}
		// 		>
		// 			<Box
		// 				sx={{
		// 					borderRadius: '5px',
		// 					p: '20px',
		// 					bgcolor: 'white',
		// 					position: 'relative',
		// 					zIndex: 1,
		// 					maxWidth: '520px',
		// 					boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.26)',
		// 				}}
		// 			>
		// 				{/* Your form content */}
		// 				<form onSubmit={handleSubmit}>{/* Form fields */}</form>
		// 			</Box>
		// 		</Box>
		// 	</Box>
		// 	<Footer emailpage={emailpage} />
		// </Box>
	);
}

export default EmailForm;
