import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import logo from '../../assets/logo.svg';

import HeadphonesIcon from '@mui/icons-material/Headphones';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import AlertDialog from '../report/AlertDilog';

const Header = ({ landingpage, emailForm, handleConsultingClick }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				left: '0',
				width: '100%',
				height: '56px',
				p: '8px',
				bgcolor: landingpage ? '' : 'rgba(255, 255, 255, 1)',
				borderBottom: emailForm ? '1px solid #EBEBEC' : '',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					width: '169px',
					height: '28px',
					justifyContent: 'space-between',
					alignItems: 'center',
					ml: '40px',
				}}
			>
				{landingpage ? (
					<img
						src={logo}
						style={{
							width: '70px',
							height: '28px',
						}}
						alt='question-image'
					/>
				) : (
					<AlertDialog />
				)}

				<Box
					sx={{
						height: '15px',
						border: '1px solid #E9E9EA',
					}}
				/>

				<Typography
					sx={{
						fontWeight: 'bold',
						fontSize: '15px',
						fontFamily: 'Figtree',
						lineHeight: '15px',
						color: '#26282C',
					}}
				>
					Mail Grader
				</Typography>
			</Box>
			<Box sx={{ mr: '40px' }}>
				{landingpage ? (
					<a
						href='https://neilpatel.com/contact/?utm_medium=ref&utm_source=mail-grader&utm_campaign=us-mkt-campaigns-mql-tool-mail-grader&utm_content=neil-patel-contact-page&utm_term=in-tool-help-button'
						target='_blank'
						rel='noopener noreferrer'
						style={{ textDecoration: 'none' }}
					>
						<Button
							sx={{
								fontWeight: '600px',
								fontSize: '14px',
								lineHeight: '16px',
								color: '#26282C',
								fontFamily: 'Figtree',
								textTransform: 'none',
							}}
							startIcon={<MailOutlineIcon />}
						>
							Contact Us
						</Button>
					</a>
				) : (
					!emailForm && (
						<Button
							onClick={handleConsultingClick}
							sx={{
								fontWeight: '400px',
								fontSize: '16px',
								lineHeight: '25.6px',
								color: '#26282C',
								fontFamily: 'Figtree',
							}}
							startIcon={<HeadphonesIcon sx={{ color: '#A3B0B3' }} />}
						>
							Consulting
						</Button>
					)
				)}
			</Box>
		</Box>
	);
};

export default Header;
