// ProgressBar.js
import React from 'react';
import { LinearProgress, Typography } from '@mui/material';

const ProgressBar = ({ progress }) => {
	return (
		<div>
			<LinearProgress
				variant='determinate'
				value={progress}
				sx={{
					backgroundColor: 'white',
					'& .MuiLinearProgress-bar': {
						backgroundImage: 'linear-gradient(95deg,  #FF9D29,  #FF5E29)',
					},
				}}
			/>
		</div>
	);
};

export default ProgressBar;
