import React from 'react';
import { Typography, Box } from '@mui/material';
import { useQuestionContext } from '../../context/QuestionContext';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DoneIcon from '@mui/icons-material/Done';
const steps = [
	{ id: 5, step: 1, label: 'Sends' },
	{ id: 6, step: 2, label: 'Contacts' },
	{ id: 7, step: 3, label: 'Analytics' },
	{ id: 8, step: 4, label: 'Creative' },
	{ id: 9, step: 5, label: 'Automation' },
];

const StepCircle = ({ data }) => {
	const { state } = useQuestionContext();
	const { currentCategory } = state;

	const gradientStyle = {
		background: 'linear-gradient(95deg,  #FF9D29,  #FF5E29)',
	};

	return (
		<Box
			sx={{
				display: 'flex',
				zIndex: '999',
				bgcolor: 'white',
			}}
		>
			{data?.map((stepData, index) => (
				<Box
					key={index}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Box
							sx={{
								width: '48px',
								height: '48px',
								border: '3px',
								borderRadius: '1000px',

								bgcolor:
									currentCategory?.name === stepData.name
										? gradientStyle
										: '#F5F5F5',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{currentCategory?.name === stepData.name ? (
								<MoreHorizIcon sx={{ color: '#FFFFFF' }} />
							) : currentCategory?.id > stepData.id ? (
								<DoneIcon sx={{ color: '#BEBFC0' }} />
							) : (
								<Typography
									sx={{
										fontFamily: 'figtree',
										fontWeight: '600px',
										fontSize: '16px',
										lineHeight: '25.6px',
										color: '#BEBFC0',
									}}
								>
									{index + 1}
								</Typography>
							)}
						</Box>

						<Box sx={{ pt: '18px' }}>
							<Typography
								sx={{
									fontFamily: 'Figtree',
									fontWeight: 300,
									fontSize: '16px',
									lineHeight: '25.6px',
									color: '#26282C',
									fontWeight:
										currentCategory?.name === stepData.name ? 'bold' : '',
								}}
							>
								{stepData.name}
							</Typography>
						</Box>
					</Box>
					{index !== steps.length - 1 && (
						<Box
							sx={{
								width: '66px',
								mt: '24px',
								ml: '20px',
								mr: '20px',
								borderTop: '1px dotted #E9E9EA',
								visibility: index === steps.length - 1 ? 'hidden' : 'visible',
							}}
						/>
					)}
				</Box>
			))}
		</Box>
	);
};

export default StepCircle;
