import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import Question from './Questions';
import StepCircle from './StepCircle';
import Footer from './Footer';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import { useQuestionContext } from '../../context/QuestionContext';
import ProgressBar from './LoadingBar';
import Header from './Header';

const cookie = new Cookies();

const MainPage = () => {
	const email = true;

	const [uuid, setUuid] = useState('');
	const { state, dispatch } = useQuestionContext();
	const { progress, data } = state;

	useEffect(() => {
		const existingUuid = cookie.get('uuid');

		if (existingUuid) {
			setUuid(existingUuid);
		} else {
			const newUuid = uuidv4();
			cookie.set('uuid', newUuid, { path: '/' });
			setUuid(newUuid);
		}
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/survey/categories/`
				);

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const data = await response.json();

				dispatch({ type: 'FETCH_DATA_SUCCESS', payload: data });

				let values = [];
				data.forEach((q) => {
					const obj = {};
					obj[q.name] = Array();
					values.push(obj);
				});

				dispatch({ type: 'SET_CATEGORY_NAME', payload: { values } });
			} catch (error) {
				console.error('Error fetching data:', error);
				dispatch({ type: 'FETCH_DATA_FAILURE', payload: error });
			}
		};

		fetchData();
	}, []);

	return (
		<>
			<Header emailForm={email} />
			<ProgressBar progress={progress} />
			<Container
				maxWidth={'full'}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mt: '64px',
				}}
			>
				<Box sx={{ justifyContent: 'center', display: 'flex' }}>
					<StepCircle data={data} />
				</Box>

				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Question questions={data} UUID={uuid} />
				</Box>
			</Container>
			<Footer />
		</>
	);
};

export default MainPage;
