import React, { useState, useEffect } from 'react';
import Routing from './Routing';
import { Box } from '@mui/material';

function App() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const checkIsMobile = () => {
			setIsMobile(window.innerWidth <= 1024); // Adjust the threshold as needed
		};

		checkIsMobile(); // Check initially

		const handleResize = () => {
			checkIsMobile();
		};

		window.addEventListener('resize', handleResize);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className='App'>
			{isMobile ? (
				<Box
					sx={{
						textAlign: 'center',
						position: 'absolute',
						top: '50%',
						background: 'none',
						border: 'none',
						fontWeight: '700',
						p: '0px 25px',
						fontFamily: 'Figtree',
						fontSize: '20px',
					}}
				>
					Thank you for visiting Mail Grader! Our mobile experience is still in
					progress, but you can get the best experience by using our desktop
					version.
				</Box>
			) : (
				<Routing />
			)}
		</div>
	);
}

export default App;
