import { Box, Button, InputBase, Paper, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Header from './questionnaire /Header';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import animationsvg from '../assets/landingpagefull.gif';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import boostyouremail from '../assets/boost-your-email.svg';
import stepone from '../assets/stepone.png';
import steptwo from '../assets/steps2.png';
import stepthree from '../assets/steps 3.png';
import stepfour from '../assets/step4.png';
import stepfive from '../assets/steps5.png';
import justastep from '../assets/just-a-step.svg';
import Footer from './questionnaire /Footer';
import { useNavigate } from 'react-router-dom';
import { useQuestionContext } from '../context/QuestionContext';

const landingpage = true;
const landingStepsData = [
	{
		tagline: 'TAGLINE 01',
		title: 'Workflow Analysis​',
		description:
			"The key to email success? Reaching your audience at the critical moments that influence their decisions. Identify missed opportunities and optimize your campaigns to capture leads at every stage of the buyer's journey.",
		image: stepone,
	},

	{
		tagline: 'TAGLINE 01',
		title: 'List Quality ​',
		description:
			'Segmentation and list hygiene are the cornerstones of high-performing email campaigns. By targeting the right audience with clean lists, you can significantly improve your deliverability and engagement.​',
		image: steptwo,
	},
	{
		tagline: 'TAGLINE 01',
		title: 'Engagement Report​',
		description:
			'Is your content resonating with your audience? Uncover hidden issues that impact email opens, click-through rates, and ultimately, conversions. ​',
		image: stepthree,
	},
	{
		tagline: 'TAGLINE 01',
		title: 'Creative Analysis​',
		description:
			'Ineffective email design is a common culprit behind lagging results. Unmask the elements that are stifling performance and get expert recommendations to optimize your layout, ensuring your emails capture attention and drive results.​',
		image: stepfour,
	},
	{
		tagline: 'TAGLINE 01',
		title: 'Integrations & Compliance Check​',
		description:
			'The right setup is crucial for your email marketing success. Find out if your campaigns are optimized for efficiency and meet regulatory requirements.​',
		image: stepfive,
	},
];

const LandingPage = () => {
	const navigate = useNavigate();

	const { dispatch, state } = useQuestionContext();
	const { locationValuesSet } = state;
	console.log('🚀 ~ LandingPage ~locationValuesSet:', locationValuesSet);

	const [inputValue, setInputValue] = useState('');
	const [error, setError] = useState('');
	const inputRef = useRef(null);

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	const handleGotoQuestion = async () => {
		const urlRegex =
			/^(?![a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:https?:\/\/)?(?:[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|localhost)(?:\/[^\s]*)?$/i;

		if (!inputValue.trim()) {
			setError('Please enter your website.');
			inputRef.current.focus();
			window.scrollTo({ top: 0, behavior: 'smooth' });
		} else if (!urlRegex.test(inputValue.trim())) {
			setError('Invalid URL.. ');
			inputRef.current.focus();
			window.scrollTo({ top: 0, behavior: 'smooth' });
		} else {
			await dispatch({
				type: 'STORE_INPUT_VALUE',
				payload: inputValue.trim(),
			});
			navigate('/question');
		}
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();
		handleGotoQuestion();
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/survey/categories/`
				);

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const data = await response.json();
				let values = [];

				data.forEach((q) => {
					const obj = {};
					obj[q.name] = Array();
					values.push(obj);
				});

				console.log('🚀 ~ fetchData ~ values:', values);

				dispatch({ type: 'SET_CATEGORY_NAME', payload: { values } });

				dispatch({ type: 'FETCH_DATA_SUCCESS', payload: data });
			} catch (error) {
				console.error('Error fetching data:', error);
				dispatch({ type: 'FETCH_DATA_FAILURE', payload: error });
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`https://ipinfo.io/?token=dd9f87125f829b`);
				const data = await response.json();
				// setPosition(data);

				dispatch({ type: 'STORE_LOCATION_VALUE', payload: data });
			} catch (error) {
				console.error('Error fetching IP information:', error);
			}
		};
		fetchData();
	}, []);
	return (
		<Box sx={{ backgroundImage: 'linear-gradient(90deg,  #FDFDFD , #FFFAF9)' }}>
			<Header landingpage={landingpage} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					mt: '20px',
				}}
			>
				<Typography
					sx={{
						fontFamily: 'Figtree',
						fontSize: '64px',
						lineHeight: '64px',
						color: '#FF5E29',
						fontWeight: 'bold',
					}}
				>
					Mail <span style={{ color: '#26282C' }}>Grader</span>
				</Typography>
				<Typography
					sx={{
						fontFamily: 'Figtree',
						fontSize: '24px',
						lineHeight: '38.4px',
						fontWeight: '300px',
						width: '736px',
						textAlign: 'center',
						mt: '8px',
						mb: '16px',
					}}
				>
					Find out if your email strategy is set up for success in less than
					five minutes with our FREE Email Audit.
				</Typography>
				<Box
					sx={{
						width: '35px',
						border: '1px solid #D4D4D5',
					}}
				/>
				<Box sx={{ display: 'flex', mt: '16px' }}>
					<StarIcon sx={{ color: '#F8BC54' }} />
					<Typography
						sx={{
							fontFamily: 'Figtree',
							fontSize: '18px',
							lineHeight: '24px',
							fontWeight: 'bold',
							ml: '4px',
						}}
					>
						Start your free email audit now and get instant insights for better
						results.
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',

						alignItems: 'start',
						flexDirection: 'column',
					}}
				>
					<Paper
						component='form'
						sx={{
							pl: '2px ',
							display: 'flex',
							alignItems: 'center',
							width: '538px',
							height: '56px',
							border: '1px solid #7BAAF7',
							borderRadius: '5px',
							mt: '20px',
						}}
						onSubmit={handleFormSubmit}
					>
						<InputBase
							ref={inputRef}
							sx={{ ml: 1, flex: 1 }}
							placeholder={error ? error : 'Enter your website...'}
							inputProps={{ 'aria-label': 'Enter your website...' }}
							value={inputValue}
							onChange={handleInputChange}
						/>

						<Button
							size='large'
							variant='contained'
							sx={{
								height: '56px',
								borderRadius: '0px 3px 3px 0px ',
								textTransform: 'none',
							}}
							endIcon={<ArrowForwardIcon />}
							onClick={handleGotoQuestion}
						>
							Get Started
						</Button>
					</Paper>
					{error && (
						<Typography
							variant='body2'
							color='error'
							sx={{ mt: '8px', ml: '4px' }}
						>
							{error}
						</Typography>
					)}
				</Box>
			</Box>

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					mt: '2px',

					height: '700px',
					width: '100%',
				}}
			>
				<img src={animationsvg} alt='animation' style={{ width: '100%' }} />
			</Box>

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-around',
					alignItems: 'center',
				}}
			>
				<Box>
					<Typography
						sx={{
							fontFamily: 'Figtree',
							fontSize: '48px',
							lineHeight: '48px',
							color: '#26282C',
							fontWeight: 'bold',
							width: '499px',

							mb: '8px',
						}}
					>
						<span style={{ color: '#FF5E29' }}>Boost</span> Your Email ROI
						Without Hiring a Professional Marketer
					</Typography>

					<Typography
						sx={{
							fontFamily: 'Figtree',
							fontSize: '20px',
							lineHeight: '32px',
							color: '#26282C',
							fontWeight: '300px',
							width: '479px',
							mb: '8px',
						}}
					>
						Answer a few questions and get personalized recommendations on how
						to optimize your email performance.
					</Typography>

					<Box sx={{ display: 'flex', alignItems: 'center', mb: '2px' }}>
						<CheckCircleIcon sx={{ color: '#60E2AC' }} />
						<Typography
							sx={{
								fontFamily: 'Figtree',
								fontSize: '18px',
								lineHeight: '28.8px',
								color: '#26282C',
								fontWeight: '600px',
								ml: '4px',
							}}
						>
							Analyze 25+ criteria​
						</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', mb: '2px' }}>
						<CheckCircleIcon sx={{ color: '#60E2AC' }} />
						<Typography
							sx={{
								fontFamily: 'Figtree',
								fontSize: '18px',
								lineHeight: '28.8px',
								color: '#26282C',
								fontWeight: '600px',
								ml: '4px',
							}}
						>
							Actionable recommendations
						</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', mb: '8px' }}>
						<CheckCircleIcon sx={{ color: '#60E2AC' }} />
						<Typography
							sx={{
								fontFamily: 'Figtree',
								fontSize: '18px',
								lineHeight: '28.8px',
								color: '#26282C',
								fontWeight: '600px',
								ml: '4px',
							}}
						>
							Free assessment worth $1,000
						</Typography>
					</Box>

					<Button
						size='large'
						variant='contained'
						sx={{
							fontSize: '16px',
							lineHeight: '16px',
							fontFamily: 'Figtree',
							fontWeight: '600px',
							height: '56px',
							textTransform: 'none',
						}}
						onClick={handleGotoQuestion}
					>
						Check Email Score Now For Free!
					</Button>
				</Box>
				<img src={boostyouremail} alt='boostyouremail' />
			</Box>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					mt: '32px',
					mb: '32px',
				}}
			>
				<Box
					sx={{
						borderRadius: '1000px',
						bgcolor: '#E8EDF5',
						p: '6px',
						fontFamily: 'Figtree',
						fontSize: '12px',
						lineHeight: '19.2px',
						fontWeight: '600px',
					}}
				>
					QUICK STEPS
				</Box>

				<Typography
					sx={{
						fontFamily: 'Figtree',
						fontSize: '48px',
						lineHeight: '48px',
						color: '#26282C',
						fontWeight: 'bold',
						width: '736px',
						mt: '8px',
					}}
				>
					<span style={{ color: '#FF5E29' }}>Super Efficient</span> Email
					Auditing in a Few Simple Steps
				</Typography>
			</Box>

			{landingStepsData.map((data, index) => (
				<Box
					key={index}
					sx={{
						display: 'flex',
						flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
						justifyContent: 'space-around',
						alignItems: 'center',
						mb: '32px',
					}}
				>
					<Box>
						<Typography
							sx={{
								fontFamily: 'Figtree',
								fontSize: '24px',
								lineHeight: '28.8px',
								fontWeight: 'bold',
								color: '#BEBFC0',
							}}
						>
							{index + 1}.
						</Typography>
						<Box
							sx={{
								borderRadius: '20px',
								backgroundImage: 'linear-gradient(180deg, #FFDED3 ,  #FFAD92)',
								fontFamily: 'Figtree',
								fontSize: '12px',
								lineHeight: '19.2px',
								fontWeight: 'bold',
								border: '1px solid #FFAD92',
								width: '81px',
								height: '32px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								mt: '8px',
								mb: '16px',
							}}
						>
							TAGLINE
						</Box>

						<Typography
							sx={{
								fontFamily: 'Figtree',
								fontSize: '36px',
								lineHeight: '43.2px',
								color: '#26282C',
								fontWeight: 'bold',
								mb: '16px',
							}}
						>
							{data.title}
						</Typography>
						<Typography
							sx={{
								fontFamily: 'Figtree',
								fontSize: '20px',
								lineHeight: '32px',
								color: '#26282C',
								fontWeight: '300px',
								width: '480px',
							}}
						>
							{/* The key to email success? Reaching your audience at the critical
							moments that influence their decisions. Identify missed
							opportunities and optimize your campaigns to capture leads at
							every stage of the buyer's journey. */}
							{data.description}
						</Typography>
					</Box>
					<img src={data.image} alt='stepone' />
				</Box>
			))}
			<Box
				sx={{
					backgroundImage: 'linear-gradient(90deg,  #FF5E29 , #FF7C51)',
					display: 'flex',
					justifyContent: 'space-around',
					alignItems: 'center',
					pt: '8px',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						left: '0',
						//bottom: '8px',
						// width: '100%',
					}}
				>
					<img src={justastep} alt='just-step' style={{ height: '184px' }} />
					<Typography
						sx={{
							fontFamily: 'Figtree',
							fontSize: '36px',
							lineHeight: '43.2px',
							color: '#FFFFFF',
							fontWeight: 'bold',
							width: '736px',
						}}
					>
						Get your score along with your personalized email optimization
						roadmap.​
					</Typography>
				</Box>

				<Button
					variant='contained'
					size='large'
					sx={{
						fontSize: '16px',
						lineHeight: '16px',
						fontFamily: 'Figtree',
						fontWeight: '600px',
						color: '#26282C',
						textTransform: 'none',
					}}
					style={{ background: 'white' }}
					endIcon={<ArrowForwardIcon sx={{ color: '#FF5E29' }} />}
					onClick={handleGotoQuestion}
				>
					Analyze My Email Now
				</Button>
			</Box>
			<Footer landingpage={landingpage} />
		</Box>
	);
};

export default LandingPage;
